import React from "react";
import { Box, Grid, Typography } from "@mui/material"
import Header from "../../component/header/header";
import Footer from "../../component/footer/footer";
import WavingHandIcon from '@mui/icons-material/WavingHand';
import "./profile.css"
import SearchSummary from "./profile-component/searchSummary"
import EditProfile from "./profile-component/profileWrapper"

function Profile() {
    debugger
    const searches = JSON.parse(sessionStorage.getItem(process.env.REACT_APP_STORAGE_USER)).searches
    
    return (
        <>
            <Header />
            <Box sx={{
                flexGrow: 1,
                backgroundColor: "#0067FF5C",
                borderRadius: { xs: "0px 0px 48px 48px", lg: "0px 0px 200px 200px" },
            }} className="profile-page p-5">
                <Grid container spacing={3} className=" white-bg-wrap p-3">
                    <Grid item xs={12} md={12}>
                        <Typography variant="h5" style={{paddingBottom: "25px"}}>
                            Hello  <WavingHandIcon />
                        </Typography>
                        <EditProfile/>
                        <Grid variant="div" className="mb-4">
                            <Typography>
                                Active Searches
                            </Typography>
                            {Object.entries(searches).map(([k, v]) => (
                                <SearchSummary
                                    min_rent={v.min_rent}
                                    max_rent={v.max_rent}
                                    city={v.city}
                                    bedrooms={v.bedrooms}
                                    key={k}
                                    search_nr={k}
                                />
                            ))}
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12} md={5}>
                        <Grid variant="div" className="mb-4 mt-4">
                            <Typography>
                                <h5>Recent Matches</h5>
                            </Typography>
                            <ProfileCard />
                        </Grid>
                    </Grid> */}
                    {/* <Grid item xs={12} className="text-end py-2">
                        <Link className="see-text">See More</Link>
                    </Grid> */}
                </Grid>
            </Box>

            <Footer />
        </>
    )
}
export default Profile;