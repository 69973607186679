export const RecentList = [
    {
        id: 1,
        image: "/img/match.png",
        category: "New",
        title: "Van Ostadestraat",
        subtitle: "Amsterdam De Pijp",
        size: 34,
        perMonth: "Per Month",
        price: 1650,
        via: "Pararius",
        listedOn: "Oct. 24, 2023",
    },
    {
        id: 2,
        image: "/img/match.png",
        category: "New",
        title: "Van Ostadestraat",
        subtitle: "Amsterdam De Pijp",
        size: 34,
        perMonth: "Per Month",
        price: 1650,
        via: "Pararius",
        listedOn: "Oct. 24, 2023",
    },
    {
        id: 3,
        image: "/img/match.png",
        category: "New",
        title: "Van Ostadestraat",
        subtitle: "Amsterdam De Pijp",
        size: 34,
        perMonth: "Per Month",
        price: 1650,
        via: "Pararius",
        listedOn: "Oct. 24, 2023",
    },
    {
        id: 4,
        image: "/img/match.png",
        category: "New",
        title: "Van Ostadestraat",
        subtitle: "Amsterdam De Pijp",
        size: 34,
        perMonth: "Per Month",
        price: 1650,
        via: "Pararius",
        listedOn: "Oct. 24, 2023",
    },
    {
        id: 5,
        image: "/img/match.png",
        category: "New",
        title: "Van Ostadestraat",
        subtitle: "Amsterdam De Pijp",
        size: 34,
        perMonth: "Per Month",
        price: 1650,
        via: "Pararius",
        listedOn: "Oct. 24, 2023",
    },
    {
        id: 6,
        image: "/img/match.png",
        category: "New",
        title: "Van Ostadestraat",
        subtitle: "Amsterdam De Pijp",
        size: 34,
        perMonth: "Per Month",
        price: 1650,
        via: "Pararius",
        listedOn: "Oct. 24, 2023",
    },
    {
        id: 7,
        image: "/img/match.png",
        category: "New",
        title: "Van Ostadestraat",
        subtitle: "Amsterdam De Pijp",
        size: 34,
        perMonth: "Per Month",
        price: 1650,
        via: "Pararius",
        listedOn: "Oct. 24, 2023",
    },
    {
        id: 8,
        image: "/img/match.png",
        category: "New",
        title: "Van Ostadestraat",
        subtitle: "Amsterdam De Pijp",
        size: 34,
        perMonth: "Per Month",
        price: 1650,
        via: "Pararius",
        listedOn: "Oct. 24, 2023",
    },
]