import React, { useRef } from "react";
import { Grid, Autocomplete, TextField, Link} from "@mui/material"

import EmailInput from "../../../component/user_profile/emailInput"
import CustomPhoneInput from "../../../component/user_profile/customPhoneInput"
import NameInput from "../../../component/user_profile/nameInput"
import DeleteIcon from '@mui/icons-material/Delete';

import {emailConst} from '../../../component/user_profile/emailInput'
import "../profile.css"

const CONTACT_METHODS_AVAILABLE = ["whatsapp", "email"]

function EditProfileData(props) {
    debugger
    const phoneReferenceField = useRef(null)
    const emailReferenceField = useRef(null)

    const handleNotificationSelectionChange = (value) => {
       props.handleChange('is_notified_by', value)
    }

    const handleFirstNameChange = (event) => {
        props.handleChange("first_name", event.target.value);
    };

    const handleLastNameChange = (event) => {
        props.handleChange("last_name", event.target.value);
    };

    const handlePhoneChange = (value) => {
       props.handleChange("phone_number", value)
    };

    const handleEmailChange = (event) => {
       debugger
       props.handleChange(emailConst, event.target.value);
    };
    
    const handleContactMethodChange= (value) => {
        debugger
        props.handleChange('is_notified_by', value);
        
        if(value === "phone"){
            if (phoneReferenceField.current) {
                phoneReferenceField.current.focus();
            }
        } else if(value === "email"){
            if (emailReferenceField.current) {
                emailReferenceField.current.focus();
            }
        }
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                    <EmailInput
                        contactMethod={props.formData.is_notified_by}
                        email={props.formData.email}
                        handleNotificationSelectionChange={handleNotificationSelectionChange}
                        handleEmailChange={handleEmailChange}
                        isSignUp={false}
                        ref={emailReferenceField}
                     />
                </Grid>
                <Grid item xs={12} md={5}>
                    <CustomPhoneInput
                        contactMethod={props.formData.is_notified_by}
                        phone={props.formData.phone_number}
                        handleNotificationSelectionChange={handleNotificationSelectionChange}
                        handlePhoneChange={handlePhoneChange}
                        isSignUp={false}
                        ref={phoneReferenceField}
                    />
                </Grid>
                <Grid item xs={12} md={5}>
                    <div
                        className="input-field-group"
                    >
                        <label>Is notified by: </label>
                        <Autocomplete
                            disablePortal
                            fullWidth
                            disableClearable
                            id="contact_method_elem"
                            value={props.formData.is_notified_by}
                            options={CONTACT_METHODS_AVAILABLE}
                            onChange={(event, newValue) => {handleContactMethodChange(newValue)}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label=""
                                    placeholder="Select contact method..."
                                />
                            )}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={5} style={{paddingLeft: "40px"}}>
                    Subscription type: FREE
                </Grid>
                <Grid item xs={12} md={5}>
                    First Name: 
                    <label style={{fontSize: "12px", paddingLeft: "10px"}}>optional</label>
                    <NameInput
                        handleNameChange={handleFirstNameChange}
                        name={props.formData.first_name}
                        isFirstName={true}
                    />
                </Grid>
                <Grid item xs={12} md={5}>
                    Last Name: 
                    <label style={{fontSize: "12px", paddingLeft: "10px"}}>optional</label>
                    <NameInput
                        handleNameChange={handleLastNameChange}
                        name={props.formData.last_name}
                        isFirstName={false}
                    />
                </Grid>
                <Grid className="delete-account" variant="div" item xs={20} md={2} style={{display: "flex"}}>
                    <Link onClick={props.deleteAccount}>Delete account<DeleteIcon /></Link>
                </Grid>
            </Grid>
        </>
    )
}
export default EditProfileData;