import React from "react";
import Header from "../../component/header/header";
import Footer from "../../component/footer/footer";
import Container from '@mui/material/Container'

function CookiePolicy() {
    return (
        <>
            <Header />
            <div class="location-wrapper bg-fill-full">
                <Container>
                    <div class="inner-location">
                        <h1>Cookie Policy</h1>

                        <p>Last updated: 21.06.2024</p>

                        <h2>1. What are cookies?</h2>
                        <p>Cookies are small text files that are stored on your computer or mobile device when you visit a website. They allow the website to recognize your device and store some information about your preferences or past actions.</p>

                        <h2>2. How we use cookies</h2>
                        <p>We use cookies for several purposes:</p>
                        <ul>
                            <li><strong>Essential cookies:</strong> These cookies are necessary for the website to function properly.</li>
                            <li><strong>Third party cookies:</strong> These cookies are set by an domain other than hounder.ai. These cookies are also contribute to the functionality of the website.</li>
                        </ul>

                        <h2>3. Your consent</h2>
                        <p>By using our website, you consent to the use of cookies in accordance with this Cookie Policy. You can withdraw your consent at any time by deleting cookies from your browser settings.</p>

                        <h2>4. Managing cookies</h2>
                        <p>You can control and/or delete cookies as you wish. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being placed. If you do this, however, you may have to manually adjust some preferences every time you visit a site and some services and functionalities may not work.</p>

                        <h2>5. Contact us</h2>
                        <p>If you have any questions about our Cookie Policy, please contact us:</p>
                        <ul>
                            <li>Email: info@hounder.ai</li>
                        </ul>
                    </div>
                </Container>
            </div>
            <Footer />
        </>
    )
}

export default CookiePolicy;
