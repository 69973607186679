//import { Button, Link } from "@mui/material";
import React, {useState, useEffect } from "react";
import 'react-phone-number-input/style.css'; // Import the styles
import TextField from '@mui/material/TextField';

export function isValidName(name){
    debugger
    return !name || (name.length < 50 && /^[A-Za-z ]*$/.test(name))
}
function NameInput(props) {
    const [isNameFieldFocused, setiIsNameFieldFocused] = useState(false)
    const [nameBorderColor, setNameBorderColor] = useState("white")

    function checkName(event){
        debugger
        props.handleNameChange(event)
    }

    useEffect(() => {
        debugger
        const isValid = isValidName(props.name)
        if(!isValid && !isNameFieldFocused){
            setNameBorderColor("red")
        }
        else{
            setNameBorderColor("white")
        }
    }, [isNameFieldFocused])

    return(
        <>
            <div
                className="input-field-group"
            >
                <TextField
                    name="name"
                    placeholder={props.isFirstName ? "First Name" : "Last Name"}
                    variant="outlined"
                    fullWidth
                    value={props.name}
                    style={{
                        "border": `1px solid ${nameBorderColor}`,
                        "opacity": 1
                    }}
                    onBlur={() => {setiIsNameFieldFocused(false)}}
                    onFocus={() => {setiIsNameFieldFocused(true)}}
                    onChange={checkName}
                />
            </div>
        </>
    )
}

export default NameInput;