import * as React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';

export default function CardItem({ image, category, title, subtitle, size, perMonth, price, via, listedOn }) {
    return (
        <Box sx={{ backgroundColor: "white", p: "16px", borderRadius: "12px", boxShadow: { xs: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)", md: "none" }, backdropFilter: { xs: "blur(15px)", md: "none" } }}>
            <Box sx={{ position: "relative", paddingBottom: "16px", borderRadius: "12px", }}>
                {image && <img src={image} alt="card-image" width="100%" />}
                {category && <Button sx={{
                    backgroundColor: "#06F",
                    border: "none",
                    outline: "none",
                    color: "#fff",
                    p: "4px 13px",
                    fontSize: "12px",
                    fontWeight: "400",
                    lineHeight: "normal",
                    position: "absolute",
                    top: "16px",
                    left: "16px",
                    '&:hover': {
                        p: "4px 13px",
                        backgroundColor: "#06F",
                        border: "none",
                        outline: "none",
                    }
                }} variant="outlined">
                    {category}
                </Button>}
            </Box>
            <Box>
                <Typography variant='h3' sx={{
                    color: { xs: "#549DF0", md: "#000" },
                    fontSize: "18px",
                    fontWeight: { xs: "700", md: "400" },
                    lineHeight: "125%",
                    marginBottom: "8px",
                }} >
                    {title}
                </Typography>
                <Typography variant='h6' sx={{
                    color: "#141519",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "125%",
                    marginBottom: "18.64px",
                }} >
                    {subtitle}
                </Typography>
                <Typography variant='h5' sx={{
                    color: "#141519",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "150%",
                    letterSpacing: "-0.25px",
                    marginBottom: "8px",
                }} >
                    {size}m<sup>2</sup> | bedrooms
                </Typography>
                <Stack sx={{ marginBottom: "16px", flexDirection: "row", gap: "10px", alignItems: "center", justifyContent: "space-between", }}>
                    <Typography variant='h5' sx={{
                        color: "#141519",
                        fontSize: "18px",
                        fontWeight: { xs: "700", md: "400" },
                        lineHeight: { xs: "125%", md: "150%" },
                    }} >
                        {perMonth}
                    </Typography>
                    {price && <Typography variant='h5' sx={{
                        color: "#141519",
                        fontSize: "18px",
                        fontWeight: { xs: "700", md: "400" },
                        lineHeight: { xs: "125%", md: "150%" },
                        textAlign: "right",
                    }} >
                        €{price}
                    </Typography>}
                </Stack>
                <Stack sx={{ marginBottom: "16px", flexDirection: "row", gap: "10px", alignItems: "center", justifyContent: "space-between", }}>
                    <Typography variant='h5' sx={{
                        color: "#141519",
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "200%",
                        letterSpacing: "-0.25px",
                    }} >
                        Via {via}
                    </Typography>
                    <Typography variant='h5' sx={{
                        color: "#141519",
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "200%",
                        letterSpacing: "-0.25px",
                        textAlign: "right",
                    }} >
                        Listed since {listedOn}
                    </Typography>
                </Stack>
                <Button sx={{
                    p: "9px 24px",
                    border: "none",
                    outline: "none",
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "150%",
                    background: { xs: "#549DF0", md: "#06F" },
                    borderRadius: "25px",
                    width: "100%",
                    textTransform: "capitalize",
                    '&:hover': {
                        border: "none",
                        outline: "none",
                    }
                }} variant="contained">
                    React Now
                </Button>
            </Box>
        </Box>
    );
}