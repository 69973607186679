async function makeRequest(url, body, headers, method){
    try {
        const requestOptions = {
            method: method, 
            headers: headers, 
            body: body, 
            credentials: 'include', // Include credentials (cookies) in the request
        } 
        debugger
        const response = await fetch(url, requestOptions);
        debugger
        const data = await response.json();
        return {"statusText": response.statusText, "status": response.status, "body": data}
    } catch (error) {
        return {"statusText": 404, "status":  "Encoutered Error"}
    }
}

export async function postToBackend(url, body, headers= {'Content-Type': 'application/json'}){
    return await makeRequest(url, JSON.stringify(body), headers, 'POST');
}

export async function getFromBackend(url, body= null, headers= {'Content-Type': 'application/json'}){
    return await makeRequest(url, body, headers, 'GET');
}

export async function putToBackend(url, body, headers= {'Content-Type': 'application/json'}){
    return await makeRequest(url, JSON.stringify(body), headers, 'PUT');
}

export async function deleteFromBackend(url, headers={'Content-Type': 'application/json'}, body=null){
    return await makeRequest(url, JSON.stringify(body), headers, 'DELETE');
}