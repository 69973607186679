//import { Button, Link } from "@mui/material";
import React, {useState, useEffect} from "react";
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { priceList } from "../../../component/geogis/geogisUtility";
import { InputLabel, MenuItem, Select, Container, Button, Tooltip, Slider, SliderThumb } from "@mui/material";
import StepsOutlineComponent from './StepsOutlineComponent'

function SecondStepComponent(props) {
   const [minPrice, setMinPrice] = useState(0); // State for minimum price
   const [maxPrice, setMaxPrice] = useState(5000); // State for maximum price
   const [activeElement, setActiveElement] = useState(null);
   const [priceRange, setPriceRange] = useState([0, 5000]);


   const bedroomClick = (rooms) => {
      setActiveElement(rooms);
      props.handleChange("bedrooms", rooms)
   };
   
   const handleChange = (event, newValue) => {
      debugger
      setPriceRange(newValue);
      props.handleChange("budget", newValue)
      const nearestMin = priceList.reduce(function (prev, curr) {
         return (Math.abs(curr - newValue[0]) < Math.abs(prev - newValue[0]) ? curr : prev);
      });
      setMinPrice(nearestMin);

      const nearestMax = priceList.reduce(function (prev, curr) {
         return (Math.abs(curr - newValue[1]) < Math.abs(prev - newValue[1]) ? curr : prev);
      });
      setMaxPrice(nearestMax);
   };

   const ValueLabelComponent = (props) => {
      const { children, value } = props;

      return (
         <Tooltip
            arrow
            placement="top"
            title={value}
            open={true}
         >
            {children}
         </Tooltip>
      );
   };

   useEffect(() => {
      props.handleChange("budget", priceRange)
      if (props.formData.bedrooms && props.formData.bedrooms !== 0 && props.formData.budget !== "") {
         setActiveElement(props.formData.bedrooms);
      }
   }, [])

   const setMinPriceValue = (e) => {
      debugger
      const minValueTarget = e.target.value
      setMinPrice(minValueTarget);
      if (minValueTarget >= maxPrice) {
         setMaxPrice(minValueTarget+250);
      }
      const priceArray = [minValueTarget, maxPrice];
      setPriceRange(priceArray);
   };

   const setMaxPriceValue = (e) => {
      const maxValueTarget = e.target.value;
      setMaxPrice(maxValueTarget);
      if (maxValueTarget <= minPrice) {
         setMinPrice(maxValueTarget-250);
      }
      const priceArray = [minPrice, maxValueTarget];
      setPriceRange(priceArray);
   };

   return (
      <div className="location-wrapper bg-fill-full">
         <Container>
            <div className="inner-location preferences">
               <div className="curve-1"></div>

               <div className="header-box">
                  <h1>Preferences</h1>
               </div>

               <StepsOutlineComponent
                  jumpTo={props.jumpTo}
                  activeStep={props.activeStep}
                  canJump={activeElement ? true : false}
               />

               <div className="preferences-tabs-listing-content">
                  <div className="preferences-form-box">
                     <form action="">
                        <div className="form-group">
                           <label for="bedrooms" className="form-title">Bedrooms</label>
                           <div className="input-field-group">
                              <div className="booking-left-bx">
                                 {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                 <a className={`numbr-count ${activeElement === '1' ? 'active' : ''}`} onClick={() => bedroomClick('1')}>1</a>
                                 {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                 <a className={`numbr-count ${activeElement === '2' ? 'active' : ''}`} onClick={() => bedroomClick('2')}>2</a>
                                 {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                 <a className={`numbr-count ${activeElement === '3' ? 'active' : ''}`} onClick={() => bedroomClick('3')} >3</a>
                                 {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                 <a className={`numbr-count ${activeElement === '4' ? 'active' : ''}`} onClick={() => bedroomClick('4')} >4</a>
                                 {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                 <a className={`numbr-count ${activeElement === '5' ? 'active' : ''}`} onClick={() => bedroomClick('5')} >5</a>
                                 {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                 <a className={`numbr-count ${activeElement === '6' ? 'active' : ''}`} onClick={() => bedroomClick('6')}>6 <AddIcon /></a>
                              </div>
                              <CheckCircleIcon className={activeElement ? 'filled' : 'not-filled'} />
                           </div>
                        </div>

                        <div className="form-group budget-price" style={{ marginTop: 50 }}>
                           <label for="budget" className="form-title">Budget</label>
                           <div className="input-field-group">
                              <div className="booking-left-bx">
                                 <div className="price-slider">
                                    <div className="price-slider-wrap">
                                       {/* <div class="price-left">{priceRange[0]}€</div> */}
                                       <div className="price-left">
                                          <InputLabel
                                             sx={{
                                                fontSize: "16px",
                                                fontStyle: "normal",
                                                lineHeight: "24px",
                                                letterSpacing: "-0.25px",
                                                paddingTop: "24px",
                                                color: "#111111",
                                                fontWeight: { xs: "700", lg: "400" },
                                             }}
                                          >
                                             Minimum Price
                                          </InputLabel>
                                          <Select
                                             value={minPrice}
                                             onChange={setMinPriceValue}
                                             sx={{
                                                borderRadius: "4px",
                                                backgroundColor: "#E6F0FF",
                                                minWidth: "100%",
                                                marginTop: "8px",
                                             }}
                                          >
                                             {priceList.map((minNum, minid) => {
                                                if (minNum < 7000){
                                                   return (
                                                      <MenuItem key={minid} value={minNum} data-index={minid}>
                                                         {"€" + minNum}
                                                      </MenuItem>
                                                   );
                                                }
                                             })}
                                          </Select>
                                       </div>
                                       {/* <div class="price-right">{priceRange[1]}€</div> */}
                                       <div class="price-right">
                                          <InputLabel
                                             sx={{
                                                fontSize: "16px",
                                                fontStyle: "normal",
                                                lineHeight: "24px",
                                                letterSpacing: "-0.25px",
                                                paddingTop: "24px",
                                                color: "#111111",
                                                fontWeight: { xs: "700", lg: "400" },
                                             }}
                                          >
                                             Maximum Price
                                          </InputLabel>
                                          <Select
                                             value={maxPrice}
                                             onChange={setMaxPriceValue}
                                             sx={{
                                                borderRadius: "4px",
                                                backgroundColor: "#E6F0FF",
                                                minWidth: "100%",
                                                marginTop: "8px",
                                             }}
                                          >
                                             {priceList.map((maxNum, maxid) => {
                                                if (maxNum > 500){
                                                   return (
                                                      <MenuItem key={maxid} value={maxNum}>
                                                         {"€" + maxNum}
                                                      </MenuItem>
                                                   );
                                                }
                                             })}
                                          </Select>
                                       </div>
                                    </div>
                                    <Slider
                                       value={priceRange}
                                       onChange={handleChange}
                                       valueLabelDisplay="auto"
                                       valueLabelFormat={(value) => `${value}€`}
                                       max={8000}
                                       min={0}
                                       ValueLabelComponent={ValueLabelComponent}
                                       components={{
                                          Thumb: SliderThumb,
                                       }}
                                    />
                                 </div>
                              </div>
                              <CheckCircleIcon className={activeElement ? 'filled' : 'not-filled'} />
                           </div>
                        </div>

                        <div className="form-group">
                           <Button
                              className={activeElement ? 'submit-button submitted' : 'submit-button disabled'}
                              variant="contained"

                              color="primary"
                              endIcon={<ArrowForwardIcon />}
                              onClick={() => props.jumpTo(3)}
                           >
                              Create a new search
                           </Button>
                        </div>
                     </form>
                  </div>
               </div>
            </div>

         </Container>
         <div className="curve-2"></div>
         <div className="curve-3"></div>
         <div className="curve-4"></div>
      </div>

   )
}

export default SecondStepComponent;