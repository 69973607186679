import { Button, Link, Box, Typography, Grid } from "@mui/material";
import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import "./footer.css"
function Footer() {
    return(
        <>
        <Grid className="footer">
            <Navbar expand="lg" >
                <Container>
                <Navbar.Brand href="/" className="logoimg"><img src="/img/logo-icon.svg"/><span>hounder</span></Navbar.Brand>
                <Navbar aria-controls="basic-navbar-nav" />
                    <Nav className="mx-auto ">
                        <Link href="/">Home</Link>
                        <Link href="/about">About</Link>
                        <Link href="/#homePricing">Pricing</Link>
                        <Link href="#">Blog</Link>
                        <Link href="/contact-us">Contact</Link>
                    </Nav>
                    <Nav className="d-flex align-items-center">
                        <Link href="#" className="m-0"><img src="/img/Facebook.svg"/></Link>
                        <Link href="#" className="m-0"><img src="/img/Twitter.svg"/></Link>
                        <Link href="#" className="m-0"><img src="/img/Instagram.svg"/></Link>
                        <Link href="https://www.linkedin.com/company/hounder-housing/" className="m-0"><img src="/img/LinkedIn.svg"/></Link>
                        
                    </Nav>
                </Container>
            </Navbar>
            <Box sx={{ flexGrow: 1, p: 2 }} className="mt-3 footer-bottom pt-3 pb-2 text-center">
                <Typography variant="body2" component="div">
                    Copyright © 2023 hounder | All Rights Reserved 
                </Typography>
            </Box>
        </Grid>
        </>
    )
}
export default Footer;