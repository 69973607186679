import React from 'react';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Auth0Provider } from "@auth0/auth0-react";
import { Route, Routes, BrowserRouter} from "react-router-dom";

import { HomePage } from './pages/homePage';
// import Login from './pages/login/login';
// import { Payment } from './pages/payment/payment';
import Profile from './pages/profile/profile';
import SignupComponent from './pages/signup/SignupComponent';
import { EditSearch } from './pages/edit-search/edit-search';
import { RecentMatches } from './pages/recent-matches/recent-matches';
import { About } from './pages/about/about';
import { Contact } from './pages/contact-us/contact-us';
import { Auth0RedirectURI } from './pages/facilitate-redirect/auth0RedirectURI.jsx';
import { PrivateRoute } from './component/authentication/privateRoute.jsx';
import CookiePolicy  from './pages/cookie/cookie-policy.jsx'
import CookiePopup from './component/cookie-popup/cookie-popup.jsx'

const App = () => {

  // const onRedirectCallback = (appState) => {
  //   debugger
  //   // Retrieve the previous page's URL from localStorage
  //   // const prevPath = localStorage.getItem('prevPath');
  //   // Redirect the user back to the previous page
  //   history.push(localStorage.getItem('prevPath'))
  // };

  const providerConfig = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENTID,
    // onRedirectCallback,
    authorizationParams: {
      redirect_uri: `${window.location.origin}/facilitate-redirect`,
      ...(process.env.REACT_APP_AUTH0_AUDIENCE ? { audience: process.env.REACT_APP_AUTH0_AUDIENCE } : null),
    },
    useRefreshTokens: true,
  };
  

  return (
    <Auth0Provider {...providerConfig}>
        <BrowserRouter>
          <CookiePopup/>
          <Routes>
            <Route path="/" element={<HomePage />}/>
            <Route path='/contact-us' element={<Contact />}/>
            <Route path='/about' element={<About />}/>
            <Route path="/signup" element={<PrivateRoute element={<SignupComponent/>}/>}/>
            <Route path='/profile' element={<PrivateRoute element={<Profile/>}/>}/>
            <Route path='/edit-search' element={<PrivateRoute element={<EditSearch/>}/>}/>
            <Route path='/recent-matches' element={<PrivateRoute element={<RecentMatches/>}/>}/>
            <Route path='/facilitate-redirect' element={<Auth0RedirectURI />}/>
            <Route path='/cookies' element={<CookiePolicy/>}/>
            {/* <Route path="/login" element={<Login />}/> */}
            {/* <Route path='/payment' element={<Payment />}/> */}
          </Routes>
      </BrowserRouter>
    </Auth0Provider>
  );
}

export default App;