export function setItemWithExpiry(key, value, expiryInDays=null, expirationTimestamp=null) {
    const now = new Date();
    const item = {
        value: value,
        expiry: expiryInDays ? now.getTime() + expiryInDays * 86400000 : expirationTimestamp,
    };
    localStorage.setItem(key, JSON.stringify(item));
}

export function getItemWithExpiry(key) {
    debugger
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
        return null;
    }
    const item = JSON.parse(itemStr);
    
    const now = new Date();
    // Convert Unix timestamp to milliseconds (JavaScript uses milliseconds)
    const timestampMilliseconds = item.expiry * 1000;
    // Create a Date object from the timestamp
    const dateFromTimestamp = new Date(timestampMilliseconds);

    if (now.getTime() > dateFromTimestamp) {
        localStorage.removeItem(key);
        return null;
    }
    return item.value;
}