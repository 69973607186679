


function StepsOutlineComponent(props) {
    return (
        <>
            <div class="steps-tabs">
                <div onClick={() => props.jumpTo(1, props.canJump)} className={`step-cell ${props.activeStep > 1 ? 'active' : ''}`}>
                    <cite>1</cite>
                   <span>Location</span>
                </div>

                <div onClick={() => props.jumpTo(2, props.canJump)} className={`step-cell ${props.activeStep > 2 ? 'active' : ''}`}>
                   <cite>2</cite>
                    <span>Selection</span>
                </div>

                <div onClick={() => props.jumpTo(3, props.canJump)} class="step-cell ">
                   <cite>3</cite>
                   <span>Done!</span>
                </div>
             </div> 
        </>
    )
}

export default StepsOutlineComponent
