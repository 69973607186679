//import { Button, Link } from "@mui/material";
import React, {useState, useEffect} from "react";
import Container from '@mui/material/Container';
import 'react-phone-number-input/style.css'; // Import the styles
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import StepsOutlineComponent from './StepsOutlineComponent'
import {isValidPhone, phoneConst} from '../../../component/user_profile/customPhoneInput'
import {isValidEmail, emailConst} from '../../../component/user_profile/emailInput'
import EmailInput from "../../../component/user_profile/emailInput"
import CustomPhoneInput from "../../../component/user_profile/customPhoneInput"


function ThirdStepComponent(props) {
      debugger
      const auth0_user_data = JSON.parse(sessionStorage.getItem(process.env.REACT_APP_STORAGE_USER_AUTH0_DATA))
      const [contactMethod, setContactMethod] = useState(auth0_user_data ? "email" : null);
      const [email, setEmail] = useState(auth0_user_data ? auth0_user_data.email : null);
      const [phone, setPhone] = useState(null);

      const handleNotificationSelectionChange = (value, referenceField) => {
         debugger
         setContactMethod(value)
         props.handleChange('is_notified_by', value)
         
         if (referenceField.current) {
            referenceField.current.focus();
         }
      }

      const handlePhoneChange = (value) => {
         setPhone(value);
         props.handleChange("phone_number", value)
      };

      const handleEmailChange = (event) => {
         debugger
         setEmail(event.target.value);
         props.handleChange(emailConst, event.target.value);
      };

      useEffect(()=>{
         debugger
         if(props.formData.phone && props.formData.phone!=="" && props.formData.is_notified_by===phoneConst){
            setPhone(props.formData.phone);
            setContactMethod(props.formData.is_notified_by);
         }
         else if (props.formData.email && props.formData.email!=="" && props.formData.is_notified_by===emailConst){
            setEmail(props.formData.email);
            setContactMethod(props.formData.is_notified_by);
         }
         else if((!props.formData.email || props.formData.email==="") && email){
            props.handleChange(emailConst, email)
            props.handleChange('is_notified_by', emailConst)
         }
         else if((!props.formData.phone || props.formData.phone==="") && phone){
            props.handleChange("phone_number", phone)
            props.handleChange('is_notified_by', phoneConst)
         }
      }, []);
   

    return (
        <div class="location-wrapper bg-fill-full">
            <Container>
               <div class="inner-location">
                  <div class="curve-1"></div>
                  <div class="header-box">
                     <h1>Finalize</h1>
                  </div>

                     <StepsOutlineComponent
                        jumpTo={props.jumpTo}
                        activeStep={props.activeStep}
                        canJump={true}
                     />
                     <div class="tabs-listing-content finalize-wrap">
                        <div class="form-box">
                           <p style={{"textAlign": "center", "fontSize": "19px", "paddingTop": "20px"}}>How would you like to be notified of new matches?</p>
                           <form action="">
                                 <EmailInput
                                    contactMethod={contactMethod}
                                    email={email}
                                    handleNotificationSelectionChange={handleNotificationSelectionChange}
                                    handleEmailChange={handleEmailChange}
                                    isSignUp={true}
                                 />
                                 <p style={{"textAlign": "center", "fontSize": "20px", "paddingTop": "20px"}}> Or</p>
                                 <CustomPhoneInput
                                    contactMethod={contactMethod}
                                    phone={phone}
                                    handleNotificationSelectionChange={handleNotificationSelectionChange}
                                    handlePhoneChange={handlePhoneChange}
                                    isSignUp={true}
                                 />
                                 <div className="form-group">
                                    <button
                                       onClick={props.signupUser}
                                       className={(
                                             (isValidEmail(email) && contactMethod === emailConst)
                                             ||
                                             (isValidPhone(phone) && contactMethod === phoneConst)
                                          )
                                          ? 'submit-button submitted' : 'submit-button disabled'
                                       }
                                       type="button"
                                       value=""
                                    >
                                       Find Homes <ArrowForwardIcon />
                                    </button>
                                 </div>
                           </form>
                        </div>
                     </div>
                  </div>
            </Container>
            <div class="curve-2"></div>
            <div class="curve-3"></div>
            <div class="curve-4"></div>
        </div>

    )
}

export default ThirdStepComponent;