import React, { useEffect, useState } from 'react'
import Header from '../component/header/header'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Grid, Box, TextField, Button, Typography, Card, CardContent, Autocomplete} from '@mui/material'
import CardShared from '../component/card/card'
import './style.css'
import ReviewSlider from '../component/slider/slider'
import Footer from '../component/footer/footer'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { allDutchGeoGis } from '../component/geogis/geogisUtility'
import { useNavigate } from 'react-router-dom'

export function HomePage() {
    const [selectCities, setSelectCities] = useState([])
    const [city, setCityVal] = useState('')
    const navigate = useNavigate()

    useEffect(() => {
        setSelectCities(allDutchGeoGis.getCities())
    }, [])

    const changePage = async () => {
        debugger;
        navigate('/signup', {
            state: {
                citySelected: city,
            },
        })
    }

    const setCity = async (value) => {
        if (value === null) {
            return
        }
        setCityVal(value)
    }

    return (
        <>
            <Header />
            <Box sx={{ flexGrow: 1 }} className="banner">
                <Grid container spacing={2}>
                    <Grid item xs={12} className="text-center">
                        <h1 className="homepageTitle">
                            Your house hunting 
                            <br/>companion
                        </h1>
                        <p className="homepageFirstText">
                            Sign up to receive instant mobile or email notifications <br/>anout new apartment listings
                        </p>
                        {/* <TextField className="srchField mb-2" variant="outlined" placeholder="Enter your City" /><br /
                        > */}
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginBottom: 20,
                            }}
                        >
                            {/* <label for="name" className="form-title">City</label> */}
                            <div
                                className="input-field-group"
                                style={{ width: '50%' }}
                            >
                                <Autocomplete
                                    disablePortal
                                    fullWidth
                                    id="city"
                                    options={selectCities}
                                    onChange={(event, newValue) => {
                                        setCity(newValue)
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label=""
                                            placeholder="Select City..."
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <Button
                            className="common-btn get-btn mt-2"
                            onClick={() => changePage('/signup')}
                        >
                            Start Search
                        </Button>
                        <div className="banne-img">
                            <img
                                src="/img/banner-img.png"
                                alt="Boat on Amsterdam Canal"
                            />
                        </div>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ p: 4 }} id="homeProduct">
                <Grid
                    container
                    className="section-wrap digital-section mt-5 mb-5 align-items-center"
                >
                    <Grid item xs={12} md={7}>
                        <Grid container>
                            <Grid item xs={12} md={8}>
                                <Typography variant="h3" style={{"padding-bottom": "25px"}}>
                                    Hounder is a digital real estate agent.
                                </Typography>
                                <Typography variant="p" gutterBottom>
                                    Hounder tirelessly searches the web for
                                    housing options in the Netherlands, so that
                                    you don&apos;t have to. Our goal is to save
                                    your time and money during your search for a
                                    new home
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className="text-center text-md-end mt-5"
                            >
                                <Button
                                    className="common-btn yellow-btn"
                                    onClick={() => changePage('/signup')}
                                >
                                    Get Started
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Grid container spacing={2} className="text-center">
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <CardShared
                                            heading="Set your search filters"
                                            content="Tell us all about your dream apartment - the more we know, the better we can help!"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <CardShared
                                            heading="Receive Housing Matches"
                                            content="Hounder sends notifications about matching properties directly to your WhatsApp, Email or Telegram, the moment they are published."
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className="mt-2">
                                <Grid container>
                                    <Grid item xs={12} md={3}></Grid>
                                    <Grid item xs={12} md={6}>
                                        <CardShared
                                            heading="Respond and Rent"
                                            content="React to the mesage and be the first to Respond to the listing! Now you can start packing"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ p: 4 }} id="homeLearn">
                <Grid container className="px-2">
                    <Grid item xs={12} md={7}>
                        <Typography variant="h2" gutterBottom>
                            Viewings for the best apartments fill up within 20
                            minutes. With Hounder you can be the first to react.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ p: 4 }}>
                <Grid container className="section-wrap feature-section">
                    <Grid item xs={12} md={4} className="text-center p-3">
                        <img src="/img/feature-image-one.png" />
                        <Typography variant="h5" gutterBottom>
                            Personalised Matches
                        </Typography>
                        <Typography variant="p" gutterBottom>
                        Share your dream home preferences and let Hounder handle the rest. 
                        Our advanced matching system ensures you only receive listings that meet your specific needs, 
                        saving you time and avoiding frustration.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} className="text-center p-3">
                        <img src="/img/feature-image-two.png" />
                        <Typography variant="h5" gutterBottom>
                            Instant Alerts
                        </Typography>
                        <Typography variant="p" gutterBottom>
                            In the competitive rental market, speed is key. 
                            Hounder notifies you instantly via WhatsApp or email, 
                            giving you the upper hand to be the first to respond. 
                            Say goodbye to endless scrolling and missed opportunities.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} className="text-center p-3">
                        <img src="/img/feature-image-three.png" />
                        <Typography variant="h5" gutterBottom>
                            1,000+ Agencies
                        </Typography>
                        <Typography variant="p" gutterBottom>
                            Imagine having a tireless team of real estate experts working for you non-stop. 
                            Hounder is your digital real estate agent, 
                            constantly checking on all the major and small housing platforms and agenices, 
                            to bring you the best rental listings the moment they hit the market.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ px: 4 }} className="mt-5 mb-0">
                <Grid container>
                    <Grid item xs={12} md={7}>
                        <Typography variant="h2" gutterBottom>
                            Home searching, made <b>easy.</b>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ px: 4, py: 2 }}>
                <Grid container className="align-items-center section-wrap">
                    <Grid item xs={12} md={6} className="px-2 mb-3 mb-md-0">
                        <Grid
                            container
                            spacing={2}
                            className=" home-search-section"
                        >
                            <Grid item xs={12} md={6}>
                                <Card className="cardwrap">
                                    <CardContent>
                                        <img src="/img/icon _euro.svg" />
                                        <Typography
                                            variant="h5"
                                            component="div"
                                        >
                                            Cheaper
                                        </Typography>
                                        <Typography variant="body2">
                                            Save your money on hiring a relocation agent, hounder cost 1/100th of the average agent fee.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Card className="cardwrap">
                                    <CardContent>
                                        <img src="/img/icon _arrow.svg" />
                                        <Typography
                                            variant="h5"
                                            component="div"
                                        >
                                            Better
                                        </Typography>
                                        <Typography variant="body2">
                                            Be the first to see and respont to new listing. Hounder keeps an eye on the market 24/7, and notifies you instantly.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Card className="cardwrap">
                                    <CardContent>
                                        <img src="/img/icon _history.svg" />
                                        <Typography
                                            variant="h5"
                                            component="div"
                                        >
                                            Faster
                                        </Typography>
                                        <Typography variant="body2">
                                            Save your time on checking new or irrelevant listing on housing platform and agency website. Hounder will do that for you.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        className="ps-md-4 d-none d-md-block"
                    >
                        <img src="/img/easy.png"
                            className="w-100"
                            alt="easy-banner"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} className="ps-md-4 d-md-none">
                        <img
                            src="/img/mobile-easy.png"
                            className="w-100"
                            alt="easy-banner"
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ px: 4 }} className="mt-5 mb-0" id="homePricing">
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <Typography
                            variant="h2"
                            gutterBottom
                            className="mb-0 premium-heading"
                        >
                            Affordable premium plan or an unlimited free trial,
                            <br /> its up to <b>you.</b>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ flexGrow: 1 }} className="premium-cards px-0">
                <Grid container className="">
                    <Grid item xs={12} md={3} className="d-none d-md-block">
                        <img src="/img/premium-image.png" />
                    </Grid>
                    <Grid item xs={12} md={9} className="card-premium">
                        <Grid container className="px-3">
                            <Grid item xs={12} md={6} className="pe-md-2">
                                <Card className="cardwrap text-center">
                                    <CardContent>
                                        <Typography
                                            variant="h4"
                                            component="div"
                                        >
                                            EARLY BIRD
                                        </Typography>
                                        <Typography
                                            className="card-heading"
                                            component="div"
                                        >
                                            Free
                                        </Typography>
                                        <Button 
                                            className="common-btn yellow-btn"
                                            onClick={() => changePage('/signup')}
                                        >
                                            Set Search
                                        </Button>
                                        <Typography variant="body2">
                                            3 matches per day <br />
                                            Custom apartment matches
                                            <br />
                                            WhatsApp notifications
                                            <br />
                                            Email notifications
                                            <br />1 active search
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                className="ps-md-2 second-card"
                            >
                                <Card className="cardwrap text-center">
                                    <CardContent>
                                        <Typography
                                            variant="h4"
                                            component="div"
                                        >
                                            PREMIUM
                                        </Typography>
                                        <Typography
                                            variant="h3"        
                                            style={{
                                                paddingTop: "10%",
                                            }}                            
                                            >
                                            Advanced<br/>features<br/>coming soon...
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box
                sx={{ flexGrow: 1, p: 2 }}
                className="mt-5 mb-0 d-md-flex justify-content-between align-tems-center slider-heading"
            >
                <Typography variant="h2" gutterBottom></Typography>
                <Button
                    className="common-btn yellow-btn me-md-5"
                    onClick={() => changePage('/signup')}
                >
                    Start Searching <ArrowForwardIcon />
                </Button>
            </Box>
            {/* <ReviewSlider /> */}
            <Footer />
        </>
    )
}