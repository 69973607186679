import { Box, Grid, Stack } from "@mui/material";
import { TeamCardData } from "../../constants/team-card-data";
import { TeamCard } from "../team-card/card";

export const TeamMembers = () => {
  return (
    <Stack
      sx={{
        paddingTop: { xs: "48px", md: "102px" },
        paddingBottom: { xs: "48px", md: "0" },
      }}
    >
      <Box className="container">
        <Stack
          sx={{
            padding: { xs: "0 5px", md: "0 0", },
            display: "grid",
            alignItems: "stretch",
            gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
            gap: "24px",
            '& > *:nth-child(3)': {
              gridColumn: { sm: "span 2" },
              justifySelf: "center",
              width: { xs: "100%", sm: "50%" },
            }
          }}
        >
          {TeamCardData.map((item, idx) => (
            <TeamCard
              key={idx}
              img={item.img}
              name={item.name}
              position={item.position}
              des={item.des}
            />
          ))}
        </Stack>
      </Box>
    </Stack>
  );
};
