import React, { useEffect, useState } from 'react'
import './signup.css'
import Header from '../../component/header/header'
import Footer from '../../component/footer/footer'
import FirstStepComponent from './components/FirstStepComponent'
import SecondStepComponent from './components/SecondStepComponent'
import ThirdStepComponent from './components/ThirdStepComponent'
import { postToBackend } from '../../services/backendUtility'
import { useNavigate } from "react-router-dom";
import {isValidPhone, phoneConst} from '../../component/user_profile/customPhoneInput'
import {isValidEmail, emailConst} from '../../component/user_profile/emailInput'

export const initialFormData = {
    city_radius: null,
    neighborhoods: new Set(),
    city: null,
    bedrooms: null,
    budget: [],
    email: null,
    phone_number: null,
    is_notified_by: null,
    subscription_type: "FREE"
}

function SignupComponent() {
    debugger
    const navigate = useNavigate()
    const [activeStep, setActiveStep] = useState(1)
    const whichFormData = sessionStorage.getItem('tmp_user') ? JSON.parse(sessionStorage.getItem('tmp_user')) : initialFormData
    const [formData, setFormData] = useState(whichFormData)

    const jumpTo = (newActiveVal, canJump=true) => {
        let difference = newActiveVal - activeStep;
        if ((difference > 1 || !canJump) && !(difference < 0)) return
        setActiveStep(newActiveVal)
        showstep(activeStep)
    }

    const handleChange = (name, value) => {
        debugger
        if (name === "neighborhoods"){
            value = Array.from(value)
        }
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
        }))
    }

    useEffect(() => {
        debugger
        sessionStorage.setItem("tmp_user", JSON.stringify(formData));
    }, [formData]);
    

    const signupUser = async () =>{
        var emailNotOk = (!isValidEmail(formData.email) && formData.is_notified_by===emailConst)
        var phoneNotOk = (!isValidPhone(formData.phone_number) && formData.is_notified_by===phoneConst)
        if (emailNotOk || phoneNotOk) return;
        
        debugger;
        let navigateTo = "/"
        const response = await postToBackend(
           process.env.REACT_APP_BACKEND_INITIAL_SIGNUP,
           formData,
           {'Content-Type': 'application/json', 'Authorization': `${sessionStorage.getItem(process.env.REACT_APP_STORAGE_TOKEN)}`}
        )
        // 405 METHOD NOT ALLOWED, because filters already exist
        if (response.status === 200){
            sessionStorage.setItem(
                process.env.REACT_APP_STORAGE_USER, 
                JSON.stringify({
                    "first_name": response.body.first_name,
                    "last_name": response.body.last_name,
                    "email": response.body.email,
                    "phone_number": formData.phone_number,
                    "is_notified_by": formData.is_notified_by,
                    "subscription_type": formData.subscription_type,
                    "created_account_at": null,
                    "searches": {
                        1: {
                            "min_rent": formData.budget[0], 
                            "max_rent": formData.budget[1],
                            "bedrooms": formData.bedrooms,
                            "city": formData.city,
                            "city_radius": formData.city_radius,
                            "neighborhoods": Array.from(formData.neighborhoods).map(item => item.startsWith('- ') ? item.slice(2) : item),
                            "filters_set_at": null
                        }
                    }
                }))
            navigateTo = "/profile"
        }
        else if (response.status === 405){
            sessionStorage.setItem(process.env.REACT_APP_STORAGE_USER, JSON.parse(response.body))
            navigateTo = "/profile"
        }
        else {
            alert('There was an error');
            navigateTo = "/signup"
        }
        sessionStorage.removeItem(process.env.REACT_APP_STORAGE_USER_AUTH0_DATA)
        sessionStorage.removeItem("tmp_user")
        navigate(navigateTo)
     }

    function showstep(activeStep) {
        switch (activeStep) {
            case 1:
                return (
                    <FirstStepComponent
                        jumpTo={jumpTo}
                        handleChange={handleChange}
                        formData={formData}
                        activeStep={activeStep}
                    />
                )
            case 2:
                return (
                    <SecondStepComponent
                        jumpTo={jumpTo}
                        handleChange={handleChange}
                        formData={formData}
                        activeStep={activeStep}
                    />
                )
            case 3:
                return (
                    <ThirdStepComponent
                        jumpTo={jumpTo}
                        formData={formData}
                        activeStep={activeStep}
                        signupUser={signupUser}
                    />
                )
            default:
                return (
                    <FirstStepComponent
                        jumpTo={jumpTo}
                        handleChange={handleChange}
                        formData={formData}
                        activeStep={activeStep}
                    />
                )
        }
    }

    return (
        <>
            <Header />
            {activeStep === 1 && (
                <FirstStepComponent
                    jumpTo={jumpTo}
                    handleChange={handleChange}
                    formData={formData}
                    activeStep={activeStep}
                />
            )}
            {activeStep === 2 && (
                <SecondStepComponent
                    jumpTo={jumpTo}
                    handleChange={handleChange}
                    formData={formData}
                    activeStep={activeStep}
                />
            )}
            {activeStep === 3 && (
                <ThirdStepComponent
                    jumpTo={jumpTo}
                    handleChange={handleChange}
                    formData={formData}
                    activeStep={activeStep}
                    signupUser={signupUser}
                />
            )}
            <Footer />
        </>
    )
}

export default SignupComponent
