import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card , CardContent , Typography  } from '@mui/material';
import "./card.css"
function CardShared({heading, content, cardImage}) {
return(
    <>
     <Card className="cardwrap">
      <CardContent className="text-center">
        <Typography variant="h5" component="div" className="card-heading">
            {heading}
        </Typography>
        <Typography variant="body2">
        {content}
        </Typography>
      </CardContent>
    </Card>
    </>
)
}
export default CardShared;