import React, { useState } from 'react';
import "./cookie-popup.css";

const CookiePopup = () => {
    const [isOpen, setIsOpen] = useState(localStorage.getItem("allow_cookies") ? false : true);

    const handleClose = () => {
        setIsOpen(false); // Close the popup
        localStorage.setItem("allow_cookies", true)
    };

    return (
        isOpen ? 
        (
            <div className={`popup ${isOpen ? 'open' : 'closed'}`}>
                <div className="popup-content">
                    <h2>Cookie Notice</h2>
                    <p>This website uses cookies to ensure you get the best experience on our website.</p>
                    <p>By continuing to use our site, you accept our use of cookies. This wesite only uses Essential Cookies. Learn more about them in our <a href="/cookies">Cookie Policy</a></p>
                    <button onClick={handleClose}>Accept</button>
                </div>
            </div>
        ) : (
            null
        )
    );
};

export default CookiePopup;
