import React, { useState } from "react";
import Header from "../../component/header/header";
import Footer from "../../component/footer/footer";
import {
    Box,
    Button,
    InputLabel,
    Stack,
    TextareaAutosize,
    TextField,
    Typography,
} from "@mui/material";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Contact = () => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [message, setMessage] = useState('');
    const [messageError, setMessageError] = useState('');
    
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleEmailChange = (event) => {
        const inputEmail = event.target.value;
        setEmail(inputEmail);
    };

    const handleBlur = () => {
        if (validateEmail(email)) {
            setEmailError('');
        } else {
            setEmailError('Please enter a valid email.');
        }
    };

    const handleMessageChange = (event) => {
        const inputMessage = event.target.value;
        setMessage(inputMessage);
        if (inputMessage.length === 0 || inputMessage.length > 250) {
            setMessageError('Please describe your problem in not more than 250 characters');
        } else {
            setMessageError('');
        }
    };

    const handleClickSend = () => {
        // Check for errors
        if (!hasErrors) {
            // Trigger success toaster
            toast.success('Message sent!', { autoClose: 20000 });
        }
    };

    const hasErrors = !email || !!emailError  || !!messageError;
    return (
        <>
            <Header />
            <Box
                className="contact_banner"
                sx={{
                    margin: { xs: "0", md: "64px 0 35px" },
                    padding: { xs: "34px 0 0", md: "49px 0 196px" },
                    backgroundImage: { xs: 'url("/img/mobile-bg.png")', md: "none" },
                    position: "relative",
                    "&:before": {
                        content: "''",
                        position: "absolute",
                        right: "0",
                        top: "0",
                        background: "url('/img/contact-bg.png')",
                        backgroundSize: "cover",
                        borderRadius: "60px 0 0 60px",
                        width: "55%",
                        height: "100%",
                        zIndex: -1,
                        display: { xs: "none", md: "block" },
                    },
                }}
            >
                <Box className="container">
                    <Box>
                        <Stack
                            sx={{
                                flexDirection: { xs: "column", md: "row" },
                                alignItems: "center",
                                justifyContent: "center",
                                background: "#ffffffe6",
                                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                                borderRadius: "16px",
                                padding: { xs: "30px 13px 25px", md: "0 0" },
                            }}
                        >
                            <Box
                                sx={{
                                    width: { xs: "100%", md: "40%" },
                                    marginBottom: { xs: "14px", md: "0px" },
                                }}
                            >
                                <img src="/img/bubbles.png" alt="bubbles" />
                            </Box>
                            <Box
                                sx={{
                                    width: { xs: "100%", md: "60%" },
                                    padding: { xs: "0 0 10px", md: "35px 56px 25px" },
                                }}
                            >
                                <Typography
                                    variant="h2"
                                    sx={{
                                        color: "#000",
                                        textAlign: "center",
                                        fontSize: { xs: "30px", md: "28px" },
                                        fontWeight: 600,
                                        lineHeight: { xs: "30px", md: "40px" },
                                        marginBottom: { xs: "18px", md: "10px" },
                                    }}
                                >
                                    Contact Us
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#000",
                                        textAlign: "center",
                                        fontSize: { xs: "18px", md: "14px" },
                                        fontWeight: 400,
                                        lineHeight: { xs: "30px", md: "20px" },
                                        marginBottom: "18px",
                                    }}
                                >
                                    For further questions, including partnership opportunities,
                                    please email info@hounder.ai or contact using our
                                    contact form.
                                </Typography>
                                <Stack
                                    sx={{
                                        flexDirection: { xs: "column-reverse", md: "row" },
                                        alignItems: "flex-start",
                                        justifyContent: "space-between",
                                        gap: "24px",
                                    }}
                                >
                                    <Stack sx={{ width: { xs: "100%", md: "auto" } }}>
                                        <Stack
                                            direction={{ xs: "column", md: "row" }}
                                            gap={4}
                                            sx={{ marginBottom: "28px" }}
                                        >
                                            <Box>
                                                <InputLabel
                                                    sx={{
                                                        fontSize: "14px",
                                                        marginBottom: "8px",
                                                        color: "#000",
                                                    }}
                                                >
                                                    Full Name
                                                </InputLabel>
                                                <TextField
                                                    className="input-field"
                                                    placeholder="Full Name"
                                                    sx={{ width: "100%" }}
                                                />
                                            </Box>
                                            <Box>
                                                <InputLabel
                                                    sx={{
                                                        fontSize: "14px",
                                                        marginBottom: "8px",
                                                        color: "#000",
                                                    }}
                                                >
                                                    Email
                                                </InputLabel>
                                                <TextField
                                                    className="input-field"
                                                    placeholder="info@hounder.ai"
                                                    value={email}
                                                    onChange={handleEmailChange}
                                                    onBlur={handleBlur}
                                                    error={!!emailError}
                                                    helperText={emailError}
                                                    sx={{ width: "100%" }}
                                                />
                                            </Box>
                                        </Stack>
                                        <Stack>
                                            <Box>
                                                <InputLabel
                                                    sx={{
                                                        fontSize: "14px",
                                                        marginBottom: "8px",
                                                        color: "#000",
                                                    }}
                                                >
                                                    How can we help you?
                                                </InputLabel>
                                                <TextareaAutosize
                                                    className="textarea-field"
                                                    placeholder="Describe your problem in at least 250 characters"
                                                    value={message}
                                                    onChange={handleMessageChange}
                                                    onBlur={handleMessageChange}
                                                    style={{ width: "100%" }}
                                                />
                                                 {messageError && (
                                                    <div style={{ color: '#d32f2f', fontSize: '12px', marginBottom: '10px' ,height:'10px',position:'relative',top:'-10px'}}>
                                                        {messageError}
                                                    </div>
                                                )}
                                            </Box>
                                        </Stack>
                                    </Stack>
                                    <Stack
                                        flexDirection={{ xs: "row", md: "column" }}
                                        justifyContent={"space-between"}
                                        sx={{ width: { xs: "100%", md: "auto" } }}
                                    >
                                        <Box
                                            sx={{
                                                marginBottom: "50px",
                                                maxWidth: "110px",
                                            }}
                                        >
                                            <Typography
                                                variant="h5"
                                                sx={{
                                                    color: "#000",
                                                    textAlign: "left",
                                                    fontSize: { xs: "24px", md: "20px" },
                                                    fontWeight: { xs: "600", md: "400" },
                                                    lineHeight: { xs: "46px", md: "30px" },
                                                }}
                                            >
                                                Contact
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: "#000",
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    lineHeight: { xs: "22px", md: "20px" },
                                                }}
                                            >
                                                info@hounder.ai
                                            </Typography>
                                        </Box>
                                        <Box sx={{ maxWidth: "110px" }}>
                                            <Typography
                                                variant="h5"
                                                sx={{
                                                    color: "#000",
                                                    textAlign: "left",
                                                    fontSize: { xs: "24px", md: "20px" },
                                                    fontWeight: { xs: "600", md: "400" },
                                                    lineHeight: { xs: "46px", md: "30px" },
                                                }}
                                            >
                                                Based in
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: "#000",
                                                    textAlign: "left",
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    lineHeight: { xs: "22px", md: "20px" },
                                                }}
                                            >
                                                Amsterdam, NL
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </Stack>
                                <Box sx={{ textAlign: { xs: "center", md: "start" } }}>
                                    <Button
                                     disabled={hasErrors}
                                     onClick={handleClickSend}
                                        sx={{
                                            padding: "12px 24px",
                                            fontSize: "12px",
                                            backgroundColor: "#549DF0",
                                            borderRadius: "8px",
                                            color: "#fff",
                                            marginTop: "18px",
                                            boxShadow: "none !important",
                                            "&:hover": {
                                                background: "#549DF0",
                                            },
                                        }}
                                        variant="contained"
                                    >
                                        Send message
                                    </Button>
                                </Box>
                            </Box>
                        </Stack>
                    </Box>
                </Box>
                <ToastContainer position="top-right" />
            </Box>
            <Footer />
        </>
    );
};
