import Footer from "../../component/footer/footer.jsx";
import Header from "../../component/header/header.jsx";
import { AboutBanner } from "./components/banner.jsx";
import { NewsLetter } from "./components/news-letter";
import { TeamMembers } from "./components/team-members";

export const About = () => {
    return (
        <>
            <Header />
            <AboutBanner />
            <TeamMembers />
            <NewsLetter />
            <Footer />
        </>
    );
};