import { Box, InputLabel, Select, Typography, Grid, MenuItem, Link} from "@mui/material";
import React, { useState } from "react";
import { priceList } from "../../../component/geogis/geogisUtility.js";
import GEOGIS from '../../../component/geogis/geogis.jsx'
import Button from '@mui/material/Button'


const SearchInputField = (props) => {
  const [maxPrice, setMaxPrice] = useState(props.formData?.max_rent);
  const [minPrice, setMinPrice] = useState(props.formData?.min_rent);
  const [bedrooms, setBedrooms] = useState(props.formData?.bedrooms);
  const [secondStepEnable, setSecondStepEnable] = useState(false)
  
  const geogisFieldsAreSet = (val) => {
      setSecondStepEnable(val)
  }
  const setMinPriceValue = (e) => {
    const minValueTaregt = e.target.value;
    setMinPrice(minValueTaregt);
    props.handleChange('min_rent', minValueTaregt)
  };

  const setMaxPriceValue = (e) => {
    debugger
    const maxValueTarget = e.target.value;
    setMaxPrice(maxValueTarget);
    props.handleChange('max_rent', maxValueTarget)
  };

  const setBedroomsValue = (e) => {
    const bedroomValue = e.target.value;
    setBedrooms(bedroomValue);
    props.handleChange('bedrooms', bedroomValue)
  };
    
  return (
    <Box
      sx={{
        backgroundColor: "#0067FF5C",
        borderRadius: { xs: "0px 0px 48px 38px", lg: "0px 0px 200px 200px" },
        padding: { xs: "32px 37px 41px 37px", lg: "36px 110px 41px 110px" },
      }}
    >
      {" "}
      <Box className="container p-0">
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "24px",
            padding: { xs: "15px 20px 32px  20px", lg: "58px 64px 58px 64px" },
            border: "1px solid ##E6F0FF",
            boxShadow: "0px 0px 4px 0px #0067FF5C",
            backdropFilter: "blur(4.5px)",
          }}
        >
          <Link href='/profile'>
            &lt; Back
          </Link>
          <Typography
            sx={{
              fontSize: { xs: "24px", lg: "34px" },
              fontWeight: { xs: "700", lg: "400" },
              fontStyle: "normal",
              lineHeight: "46px",
              paddingTop: "25px"
            }}
          >
            Edit Preferences{" "}
          </Typography>
          <Grid container columnSpacing={{ md: "96px" }}>
            <Grid item xs={12} md={6}>
              <Box>
                <InputLabel
                  sx={{
                    fontSize: "16px",
                    fontStyle: "normal",
                    lineHeight: "24px",
                    letterSpacing: "-0.25px",
                    paddingTop: "24px",
                    color: "#111111",
                    fontWeight: { xs: "700", lg: "400" },
                  }}
                >
                  Minimum Rent
                </InputLabel>
                <Select
                  value={minPrice}
                  onChange={setMinPriceValue}
                  sx={{
                    borderRadius: "4px",
                    backgroundColor: "#E6F0FF",
                    minWidth: "100%",
                    marginTop: "8px",
                  }}
                >
                  {priceList.map((minNum, minid) => {
                    return (
                      <MenuItem key={minid} value={minNum}>
                        {"€" + minNum}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box>
                <InputLabel
                  sx={{
                    fontSize: "16px",
                    fontStyle: "normal",
                    lineHeight: "24px",
                    letterSpacing: "-0.25px",
                    paddingTop: "24px",
                    color: "#111111",
                    fontWeight: { xs: "700", lg: "400" },
                  }}
                >
                  Maximum Rent
                </InputLabel>
                <Select
                  value={maxPrice}
                  onChange={setMaxPriceValue}
                  sx={{
                    borderRadius: "4px",
                    backgroundColor: "#E6F0FF",
                    minWidth: "100%",
                    marginTop: "8px",
                  }}
                >
                  {priceList.map((maxNum, maxid) => {
                    if (maxNum > minPrice) {
                      return (
                        <MenuItem key={maxid} value={maxNum}>
                          {"€" + maxNum}
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box>
                <InputLabel
                  id="label"
                  sx={{
                    fontSize: "16px",
                    fontStyle: "normal",
                    lineHeight: "24px",
                    letterSpacing: "-0.25px",
                    paddingTop: "24px",
                    color: "#111111",
                    fontWeight: { xs: "700", lg: "400" },
                  }}
                >
                  Bedrooms
                </InputLabel>
                <Select
                  labelId="label"
                  id="select"
                  value={bedrooms}
                  sx={{
                    borderRadius: "4px",
                    backgroundColor: "#E6F0FF",
                    minWidth: "100%",
                    marginTop: "8px",
                  }}
                  onChange={setBedroomsValue}
                >
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="4">4</MenuItem>
                  <MenuItem value="5">5</MenuItem>
                  <MenuItem value="6">6 +</MenuItem>
                </Select>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box>
                <GEOGIS 
                  handleChange={props.handleChange}
                  formData={props.formData}
                  geogisFieldsAreSet={geogisFieldsAreSet}
                  fontSize={"16px"}
                />   
              </Box>        
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              marginTop: "22px",
            }}
          >
            <div className="form-group" style={{display: "flex", width: "100%"}}>
              <Button
                variant="contained"
                style={{alignItems: "center", justifyContent: "center"}}
                className={
                  secondStepEnable && props.wasChanged
                    ? "submit-button submitted"
                    : "submit-button disabled"
                }
                onClick={props.handleSubmit}
              >
                Submit
              </Button>
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SearchInputField;
