import StepsOutlineComponent from './StepsOutlineComponent'
import GEOGIS from '../../../component/geogis/geogis.jsx'
import Container from '@mui/material/Container'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Button from '@mui/material/Button'
import React, { useState } from 'react'

function FirstStepComponent(props) {
    debugger
    const [secondStepEnable, setSecondStepEnable] = useState(false)

    const geogisFieldsAreSet = (val) => {
        setSecondStepEnable(val)
    }

    return (
        <>
            <div className="location-wrapper bg-fill-full">
                <Container>
                    <h1 className="d-md-none">Location</h1>
                    <div className="inner-location">
                        <div className="header-box">
                            <h1 className="d-none d-md-block">Location</h1>
                        </div>

                        <StepsOutlineComponent
                            jumpTo={props.jumpTo}
                            activeStep={props.activeStep}
                            canJump={secondStepEnable}
                        />
                        <GEOGIS 
                            handleChange={props.handleChange}
                            formData={props.formData}
                            geogisFieldsAreSet={geogisFieldsAreSet}
                            fontSize={"18px"}
                        />
                        <div className="form-group">
                            <Button
                                className={secondStepEnable ? 'submit-button submitted' : 'submit-button disabled'}
                                variant="contained"
                                disabled={!secondStepEnable}
                                color="primary"
                                endIcon={<ArrowForwardIcon />}
                                onClick={() => props.jumpTo(2)}
                            >
                                Enter Your Preferences
                            </Button>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default FirstStepComponent
