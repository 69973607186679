import React  from "react";
import { Grid, Link, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom";

function SearchSummary(props) {
    debugger
    const navigate = useNavigate();

    const editSearch = () => {
        debugger
        navigate("/edit-search", {state: { searchNr: props.search_nr}})
    }

    return (
        <>             
            <Grid variant="div" className="profile-section d-flex justify-content-between">
                <Typography >
                    €{props.min_rent}-€{props.max_rent} in {props.city} with {props.bedrooms} bedrooms
                </Typography>
                <Grid variant="div">
                    <Link onClick={editSearch} className="edit">Edit search</Link>
                </Grid>
            </Grid>
        </>
    )
}
export default SearchSummary;