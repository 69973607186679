import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
export const NewsLetter = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubscribeClick = () => {
    if (validateEmail(email)) {
      setError('');
      // Proceed with subscription or other actions
    } else {
      setError('Please enter a valid email address');
    }
  };
  return (
    <Stack sx={{ padding: { xs: "32px 12px", md: "154px 0" } }}>
      <Box className="container">
        <Box
          sx={{
            backgroundImage: 'url("/img/form-bg.png")',
            padding: { xs: "109px 0", md: "122px 0" },
            borderRadius: "16px",
            textAlign: "center",
            color: "white",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "55%" },
              margin: "0 auto",
              padding: { xs: "0 12px", md: " 0 0" },
            }}
          >
            <Typography
              sx={{
                fontWeight: { xs: "500", md: 400 },
                marginBottom: "20px",
                fontSize: { xs: "24px", md: "48px" },
                lineHeight: "48px",
              }}
            >
              Be the first to see the news
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: "26px" }}>
              Sign up to our newsletter if you’d like to hear about our new services and offers!
            </Typography>
            <span style={{display:'flex',alignItems:'center'}}>
            <TextField
              className="newsletter-input"
              placeholder="Email here..."
              value={email}
              onChange={handleEmailChange}
              error={!!error}
              helperText={error}
              style={{ width: "100%", margin: "40px 0",marginRight:'10px' }}
            />
             {(email !== "") ? (
                validateEmail(email) ? (
                    <CheckCircleIcon className="filled" />
                ) : (
                    <CheckCircleIcon className="not-filled" />
                )
            ) : null}</span>
            <Button
              variant="contained"
              onClick={handleSubscribeClick}
              sx={{
                padding: "12px 20px",
                backgroundColor: "#fff",
                color: "#549DF0",
                fontWeight: 600,
                textTransform: "capitalize",
                fontSize: "16px",
                borderRadius: "8px",
                boxShadow: "none",
                "&:hover": {
                  backgroundColor: "#fff",
                  boxShadow: "none",
                },
              }}
            >
              Subscribe
            </Button>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};
