import { getFromBackend } from '../../services/backendUtility'
import {setItemWithExpiry, getItemWithExpiry} from '../../services/localStorageUtility'

export const priceList = [
  0, 350, 500, 750, 1000, 1250, 1500, 1750, 2000, 
  2250, 2500, 2750, 3000, 3250, 3500, 3750, 4000, 
  4250, 4500, 4750, 5000, 5250, 5500, 5750, 6000, 
  6250, 6500, 6750, 7000, 7250, 7500, 7750, 8000
  
];

function getRadius() {
  const radiusList = ["2KM", "3KM", "5KM", "8KM", "10KM", "15KM", "20KM"];
  return radiusList;
}

export const SignupService = {
  getRadius,
};

class AllDutchGeoGis{
    constructor(all_dutch_geo_gis){
        this.all_dutch_geo_gis = all_dutch_geo_gis
    }
    getGeoJSON(city) {
      function dataObject(coordinates, title, parent){
          return{
              data: {
                  "geometry": {
                      "coordinates": coordinates,
                      "type": "Polygon"
                  },
                  "type": "Feature"
              },
              title: title,
              parent: parent,
          }
      }
      
      const geoJSONData = this.all_dutch_geo_gis[city]["neighborhoods"]
      if (!geoJSONData || !Object.keys(geoJSONData).length) return []
  
      let result = []
      for (const [neighborhood, val] of Object.entries(geoJSONData)) {
          if (Array.isArray(val)){
              result.push(dataObject(val, neighborhood, null))
          }
          else{ // else it is an dictionary
              result.push(dataObject([[[null, null]]], neighborhood, null))
              for (const [nestedNeighborhood, coordinates] of Object.entries(val)) {
                  result.push(dataObject(coordinates, "- "+nestedNeighborhood, neighborhood))
              }
          }
      }
      return result
  }
    
  getCities() {
    return Object.keys(this.all_dutch_geo_gis);
  }

  shouldParentBeSelected(allStates, selectedNeighborhoods, currentlySelected){
    const allChildren = allStates.filter((geoJson) => geoJson.parent?.toLowerCase() === currentlySelected.parent?.toLowerCase())
    const selectedChildren = Array.from(selectedNeighborhoods).filter((geoJson) => geoJson.parent?.toLowerCase() === currentlySelected.parent?.toLowerCase())
    return allChildren.length === selectedChildren.length
  }
  
  getCityCenter(city) {
    return {"long": this.all_dutch_geo_gis[city].cityCenter[0], "lat": this.all_dutch_geo_gis[city].cityCenter[1]}
  }
}

debugger
if (!getItemWithExpiry(process.env.REACT_APP_STORAGE_ALL_DUTCH_GEO_GIS)){ 
  var tmp_all_dutch_geo_gis = await getFromBackend(process.env.REACT_APP_BACKEND_GET_ALL_OF_NETHERLANDS);
  setItemWithExpiry(process.env.REACT_APP_STORAGE_ALL_DUTCH_GEO_GIS, tmp_all_dutch_geo_gis["body"]["data"], 7);
}
export const allDutchGeoGis = new AllDutchGeoGis(getItemWithExpiry(process.env.REACT_APP_STORAGE_ALL_DUTCH_GEO_GIS))
