import { Box, Grid, Stack, Typography } from "@mui/material";

export const TeamCard = ({ img, name, position, des }) => {
  return (
    <Box>
      <Stack
        flexDirection={{ xs: "column", md: "row" }}
        sx={{
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          backgroundColor: "white",
          padding: { xs: "12px 8px", md: "16px" },
          borderRadius: "16px",
          alignItems: "center",
          gap: "23px",
          textAlign: { xs: "center", md: "start" },
        }}
      >
        <Box sx={{ maxWidth: "188px", minWidth: "188px", borderRadius: "10px", overflow: "hidden" }}>
          <img src={img} alt="..." />
        </Box>
        <Box>
          <Typography
            sx={{
              fontWeight: { xs: 500, md: 400 },
              color: { xs: "#252F40", md: "#4829E6CC" },
            }}
            variant="h6"
          >
            {name}
          </Typography>
          <Typography
            sx={{
              fontWeight: { xs: 500, md: 400 },
              color: "#549DF0",
              margin: "4px 0",
            }}
            variant="body1"
          >
            {position}
          </Typography>
          <Typography
            sx={{ fontWeight: 400, color: "#67748E", lineHeight: "26px" }}
            variant="body1"
          >
            {des}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};
