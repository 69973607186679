export const TeamCardData = [
    {
        id: 1,
        img: "/img/placeholder-img.png",
        name: "Mikhail Sadovnikov",
        position: "Co-Founder",
        des: "CEO"
    },
    {
        id: 2,
        img: "/img/placeholder-img.png",
        name: "Leander Finckenstein",
        position: "Co-Founder",
        des: "CTO"
    },
]