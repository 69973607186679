import React, { useState, useEffect, useRef } from 'react'
import mapboxgl from 'mapbox-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw'

import { FormControl } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Select from '@mui/material//Select'
import MenuItem from '@mui/material//MenuItem'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import InputLabel from '@mui/material/InputLabel'
import { createFilterOptions } from '@mui/material'
import * as turf from '@turf/turf'
import { SignupService, allDutchGeoGis } from './geogisUtility'
import { useLocation } from 'react-router-dom'
import Chip from '@mui/material/Chip';
import 'mapbox-gl/dist/mapbox-gl.css';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

mapboxgl.accessToken = process.env.REACT_APP_MAPBOXGL_ACCESSTOKEN

function GEOGIS(props) {
    debugger
    const mapContainer = useRef(null)
    const map = useRef(null)
    const draw = useRef(null)

    const [zoom, setZoom] = useState(6)
    const [selectNeighborhoodByRadius, setSelectNeighborhoodByRadius] = useState(false)

    const [selectedCity, setSelectedCity] = useState('')
    const [selectedNeighborhoodRadius, setSelectedNeighborhoodRadius] = useState(null)
    const [selectedNeighborhoods, setSelectedNeighborhoods] = useState(new Set())
    const [coordinates, setCoordinates] = useState({ long: 5.291, lat: 52.132 })
    
    const [availableStatesForCity, setAvailableStatesForCity] = useState([])
    const availableRadius = SignupService.getRadius()
    const availableCities = allDutchGeoGis.getCities().filter(city => city !== props.formData?.city)

    draw.current = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
            polygon: true,
            trash: true,
        },
    })

    const getNeighbourhoodsAndDrawMap = async (value, shouldNeighborhoodBeSet=true) => {
        debugger
        if(!value) return
        const cityLatLong = value && allDutchGeoGis.getCityCenter(value)

        setSelectedCity(value)
        props.handleChange('city', value)
        setCoordinates(cityLatLong)
        setZoom(10)

        drawMap({lng: cityLatLong.long, lat: cityLatLong.lat}, value)
        
        const geoJSON = allDutchGeoGis.getGeoJSON(value)
        setAvailableStatesForCity(geoJSON)
        if(shouldNeighborhoodBeSet){
            setSelectedNeighborhoods(new Set(geoJSON))
            setSelectedNeighborhoodRadius(null)
            if (geoJSON.length === 0) {
                setSelectNeighborhoodByRadius(true)
            }
        }
    }

    const drawMap = (center) => {
        let currentMap = map.current
        if (!currentMap) {
            currentMap = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/streets-v12',
                center: [center.lng, center.lat],
                zoom: zoom,
            })
            map.current = currentMap
        }
    }

    const renderSingleStates = (props, option, {selected}) => {
        const isExist = [...selectedNeighborhoods].find((elem) => elem.title?.toLowerCase() === option.title.toLowerCase())
        const isChecked = !!isExist || selectedNeighborhoods.size === availableStatesForCity.length
        return (
            <li {...props } onClick={() => {changeNeighborhoodSelection(option, isChecked)}}>
                <span>{option.title}</span>
                <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginLeft: 'auto' }}
                    checked={isChecked}
                />
            </li>
        )
    }

    const changeNeighborhoodSelection = (option, selected) => {
        debugger
        if (!selected) {
            if (option.title?.toLowerCase() === 'select all') {
                setSelectedNeighborhoods(new Set(availableStatesForCity))
            }
            else{
                debugger
                let updatedGeoJson = new Set(selectedNeighborhoods);
                const matched = availableStatesForCity.find((geoJson) => geoJson.title?.toLowerCase() === option.title?.toLowerCase())
                const matchedChildren = availableStatesForCity.filter((geoJson) => geoJson.parent?.toLowerCase() === option.title?.toLowerCase())
                if (matched) {
                    updatedGeoJson.add(matched)
                }
                if (matchedChildren.length > 0) {
                    for (let i=0; i<matchedChildren.length; ++i){
                        updatedGeoJson.add(matchedChildren[i])  
                    }
                }
                //select the parent in case all children are selected.
                else if(allDutchGeoGis.shouldParentBeSelected(availableStatesForCity, updatedGeoJson, option)){ // if no children matched, then it must be the case that it was a child was selected.
                    updatedGeoJson.add(availableStatesForCity.find((geoJson) => geoJson.title?.toLowerCase() === option.parent?.toLowerCase()))
                }
                setSelectedNeighborhoods(updatedGeoJson)
            }
        } else {
            if (option.title?.toLowerCase() === 'select all') {
                setSelectedNeighborhoods(new Set())
            }
            else {
                let updatedGeoJson = [...selectedNeighborhoods].filter((geoJson) => geoJson.title?.toLowerCase() !== option.title?.toLowerCase())
                //deselect the parent in case there are no children selected.
                if(!allDutchGeoGis.shouldParentBeSelected(availableStatesForCity, updatedGeoJson, option)){
                    updatedGeoJson = updatedGeoJson.filter((geoJson) => geoJson.title?.toLowerCase() !== option.parent?.toLowerCase())
                }
                setSelectedNeighborhoods(new Set(updatedGeoJson.filter((geoJson) => geoJson.parent?.toLowerCase() !== option.title?.toLowerCase())))
            }
        }
    }

    const handleRadiusChange = async (event) => {
        debugger
        const value = event.target.value
        setSelectedNeighborhoodRadius(value)
    }

    useEffect(() => {
        debugger
        if (selectedNeighborhoods.size <= 0 && selectedNeighborhoodRadius === null) return

        if (!selectNeighborhoodByRadius){
            props.handleChange('city_radius', 0)
            props.handleChange('neighborhoods', new Set([...selectedNeighborhoods].map((elem) => elem.title)))
        }
        else if (selectedNeighborhoodRadius !== null){
            props.handleChange('city_radius', parseInt(selectedNeighborhoodRadius.match(/\d+/)[0]))
            props.handleChange('neighborhoods', new Set())
        }
    }, [selectedNeighborhoods, selectedNeighborhoodRadius, selectNeighborhoodByRadius])

    useEffect(() => {
        if (!map.current) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/streets-v12',
                center: [coordinates.long, coordinates.lat], 
                zoom: zoom,
            })

            map.current.on('load', () => {
                if (selectNeighborhoodByRadius) {
                    if (selectedNeighborhoodRadius === null) return
                    let _center = turf.point([coordinates.long, coordinates.lat])
                    let radius = parseInt(selectedNeighborhoodRadius.match(/\d+/)[0])
                    let _circle = turf.circle(_center, radius)
                    map.current.addSource('circleData', {
                        type: 'geojson',
                        data: _circle,
                    })

                    map.current.addLayer({
                        id: 'circletrue-fill',
                        type: 'fill',
                        source: 'circleData',
                        paint: {
                            'fill-color': '#5a3fc0',
                            'fill-opacity': 0.3,
                        },
                    })
                } else {
                    if (!selectedNeighborhoods.size) return
                    selectedNeighborhoods.forEach((d) => {
                        if (!d) return
                        const ID = `maine${d.title}`
                        map.current.addSource(ID, {
                            type: 'geojson',
                            data: d.data,
                        })

                        map.current.addLayer({
                            id: ID,
                            type: 'fill',
                            source: ID,
                            layout: {},
                            paint: {
                                'fill-color': '#5a3fc0',
                                'fill-opacity': 0.3,
                            },
                        })
                    })
                }
            })

            map.current.setZoom(zoom)
            return () => {
                if (map.current) {
                    map.current.remove()
                    map.current = null
                }
            }
        }
    }, [selectedNeighborhoods, selectedNeighborhoodRadius, selectNeighborhoodByRadius, coordinates, zoom])

    const location = useLocation()
    // The order in which the useEffect are mentioned, matters!
    useEffect(() => {
        debugger;
        let initialCity = ""
        if (props.formData?.city) { 
            initialCity = props.formData?.city
        }
        else if (location.state?.citySelected ?? false) { 
            initialCity = location.state.citySelected
            location.state = null
        }
        if (props.formData?.neighborhoods.length > 0 && initialCity!== ""){
            debugger
            const tmpAvailableStatesForCity = allDutchGeoGis.getGeoJSON(initialCity)
            let neighborhoodsWithCoordinates = Array.from(props.formData?.neighborhoods, neighborhood =>
                tmpAvailableStatesForCity.find(elem => elem.title?.toLowerCase().replace("- ", "") === neighborhood.toLowerCase())
            );
            setSelectedNeighborhoods(new Set(neighborhoodsWithCoordinates))
            setSelectNeighborhoodByRadius(false)
        }
        else if (props.formData?.city_radius > 0){
            setSelectedNeighborhoodRadius(props.formData?.city_radius.toString()+"KM")
            setSelectNeighborhoodByRadius(true)
        }
        let shouldNeighborHoodBeSet = !((props.formData?.neighborhoods.length > 0) || props.formData?.city_radius > 0)
        getNeighbourhoodsAndDrawMap(initialCity, shouldNeighborHoodBeSet)
    }, [])

    useEffect(() => {
        const areSet = selectedCity && (selectNeighborhoodByRadius ? !!selectedNeighborhoodRadius : !!selectedNeighborhoods.size)
        props.geogisFieldsAreSet(areSet)
    }, [selectedCity, selectNeighborhoodByRadius, selectedNeighborhoodRadius, selectedNeighborhoods])

    
    const switchNeighborhoodSelectionType = () => {
        if (!selectNeighborhoodByRadius && selectedNeighborhoods.size > 0){
            setSelectedNeighborhoodRadius(null)
        }
        else if (selectNeighborhoodByRadius && selectedNeighborhoodRadius !== null){
            setSelectedNeighborhoods(new Set())
        }
        setSelectNeighborhoodByRadius(!selectNeighborhoodByRadius)
    }
    
    return (
        <>
            <div className="tabs-listing-content">
                <div className="location-map order-md-2">
                    <div ref={mapContainer} className="map-container"/>
                </div>
                <div className="form-box">
                    <FormControl fullWidth>
                        <div className="form-group">
                            <div className="form-group-col">
                                <label htmlFor="name" className="form-title" style={{fontSize: props.fontSize}}>
                                    City
                                </label>
                                <div className="input-field-group">
                                    <Autocomplete
                                        disablePortal
                                        fullWidth
                                        id="city"
                                        value={selectedCity}
                                        disableClearable={true}
                                        options={availableCities}
                                        onChange={(event, newValue) => {
                                            getNeighbourhoodsAndDrawMap(newValue)
                                        }}
                                        renderInput={(params) => <TextField {...params} placeholder="Select City..." />}
                                    />
                                    {selectedCity !== '' ? <CheckCircleIcon className="filled" /> : <CheckCircleIcon className="not-filled" />}
                                </div>
                            </div>
                        </div>

                        <div className="form-group" style={{paddingTop: "0px"}}>
                            <div className="form-group-col">
                                <div className=" d-flex justify-content-between d-md-renderCustomOptionblock">
                                    <label htmlFor="name" className="form-title" style={{fontSize: props.fontSize}}>
                                        Neighbourhood
                                    </label>
                                    {!selectNeighborhoodByRadius ? 
                                        (
                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                            <a 
                                                onClick={switchNeighborhoodSelectionType} 
                                                className="select-radius order-2"
                                                style={{cursor: "pointer"}}
                                            >
                                                Or select radius
                                            </a>
                                        ) : 
                                        (
                                            availableStatesForCity.length > 0 &&
                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                            <a
                                                onClick={switchNeighborhoodSelectionType}
                                                className="select-radius order-2"
                                                style={{marginLeft: 10, cursor: "pointer"}}
                                            >
                                                Or select neighbourhoods
                                            </a>
                                        )
                                    }
                                </div>
                                <div className="input-field-group">
                                    {!selectNeighborhoodByRadius && (
                                        <Autocomplete
                                            multiple
                                            fullWidth
                                            sx={{background: '#E6F0FF', borderRadius: 1}}
                                            name="states"
                                            value={[...selectedNeighborhoods].filter(item => item.title)}
                                            renderTags={() => {
                                                return [...selectedNeighborhoods].map(item => 
                                                    <Chip key={item.title} label={item.title}/>
                                            )}}
                                            isOptionEqualToValue={(option, value) => (option?.title === value?.title)}
                                            options={availableStatesForCity.filter(item => item.title)}
                                            disableClearable={true}
                                            filterOptions={(options, params) => {
                                                const filtered = createFilterOptions()(options, params)
                                                let optionName = 'Select All'
                                                return [
                                                    {
                                                        id: 0,
                                                        title: optionName,
                                                    },
                                                    ...filtered,
                                                ]
                                            }}
                                            getOptionLabel={(option) => option.title}
                                            renderOption={renderSingleStates}
                                            renderInput={(params) => <TextField {...params} placeholder="Select State..."/>}
                                        />
                                    )}
                                    {selectNeighborhoodByRadius && (
                                        <Select
                                            fullWidth
                                            sx={{background: '#E6F0FF'}}
                                            value={selectedNeighborhoodRadius}
                                            onChange={handleRadiusChange}
                                            displayEmpty="Select Radius..."
                                            renderValue={() => selectedNeighborhoodRadius === null ? <InputLabel>Select Radius...</InputLabel> : selectedNeighborhoodRadius}
                                        >
                                            {availableRadius.map((option) => {
                                                return (
                                                <MenuItem key={option} value={option}>
                                                        {'<' + option}
                                                    </MenuItem>
                                            )
                                            })}
                                        </Select>
                                    )}
                                    {(selectedNeighborhoods.size > 0 && !selectNeighborhoodByRadius) || (selectedNeighborhoodRadius !== null && selectNeighborhoodByRadius) ? 
                                        <CheckCircleIcon className="filled" /> : <CheckCircleIcon className="not-filled" />
                                    }
                        
                                </div>
                            </div>
                        </div>
                    </FormControl>
                </div>
            </div>
        </>
    )
}

export default GEOGIS
