import React, { useMemo, useState } from 'react'
import Header from '../../component/header/header'
import Footer from '../../component/footer/footer'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CardItem from './recent-matches-components/card';
import { RecentList } from './recent-matches-components/data';
import { Link } from 'react-router-dom';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { padding } from '@mui/system';
export const RecentMatches = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => {
        debugger
        setIsOpen(!isOpen);
    };
    const isCardBlur = (idx) => {
        return RecentList.length % 3 === 0 ? idx + 3 >= RecentList.length : RecentList.length % 3 === 2 ? idx + 2 >= RecentList.length : idx+1 >= RecentList.length
    }
    return (
        <>
            <Header />
            <Box sx={{
                backgroundColor: "#0067FF5C",
                paddingTop: "32px",
                paddingBottom: { xs: "47px", md: "71.08px" },
                borderRadius: { xs: "0px 0px 48px 48px", lg: "0px 0px 200px 200px" },
            }}>
                <Box className="container">
                    <Box sx={{
                        background: { xs: "white", md: "transparent" },
                        borderRadius: { xs: "24px", md: "0px" },
                        p: { xs: "31px 22px 47px", md: "0" },
                        border: { xs: "1px solid #E6F0FF", md: "none" },
                        boxShadow: { xs: "0px 0px 4px 0px rgba(0, 26, 255, 0.25)", md: "none" },
                        backdropFilter: { xs: "blur(4.5px)", md: "blur(0px)" },
                    }}>
                        <Stack sx={{
                            flexDirection: { xs: "column", md: "row" },
                            gap: { xs: "7px", md: "32px" },
                            alignItems: { xs: "flex-start", md: "center" },
                            paddingBottom: "32px",
                        }}>
                            <Link to="/profile" style={{ textDecoration: 'none' }}>
                                <Button sx={{
                                    p: 0,
                                    border: "none",
                                    outline: "none",
                                    color: "#141519",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "-0.25px",
                                    textTransform: "inherit",
                                    '&:hover': {
                                        p: 0,
                                        border: "none",
                                        outline: "none",
                                    }
                                }} variant="outlined" startIcon={<ArrowBackIcon />}>
                                    Back to profile
                                </Button>
                            </Link>
                            <Box sx={{
                                p: "16px",
                                borderRadius: "4px",
                                background: "#F5F8FF",
                                display: { md: "none" },
                                border: "0.5px solid #2960EC",

                            }}>
                                <Typography sx={{
                                    color: "#141519",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    lineHeight: "23px",
                                }}>
                                    Make sure you watch your e-mail closely. All matches will be emailed to you and you should always respond as soon as possible on those mails. This page
                                    is just to provide you with an overview!
                                </Typography>
                            </Box>
                            <div style={{display:'flex', justifyContent:'space-between',alignItems:'center',width:'82%',cursor:'pointer'}}>
                            <Typography variant='h2' sx={{
                                color: "#000",
                                fontSize: { xs: "24px", md: "36px" },
                                fontWeight: { xs: "700", md: "500" },
                                lineHeight: "46px",
                            }} >
                                Recent Matches
                            </Typography>
                            <span style={{ background: '#fff',padding: '5px',borderRadius: '5px',height: '40px',width: '40px',textAlign:'center'}}><FilterAltIcon onClick={toggleDropdown} /></span>
                                {isOpen && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '40px',
                                            right: 0,
                                            backgroundColor: '#fff',
                                            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                            borderRadius: '4px',
                                            zIndex: 9999, // Ensure it's above other elements
                                        }}
                                    >
                                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                                            <li style={{ padding: '10px 20px', cursor: 'pointer' }}>Rent</li>
                                            <hr style={{margin:'0'}}/>
                                            <li style={{ padding: '10px 20px', cursor: 'pointer' }}>Size</li>
                                            <hr style={{margin:'0'}}/>
                                            <li style={{ padding: '10px 20px', cursor: 'pointer' }}>Bedrooms</li>
                                        </ul>
                                    </Box>
                                )}
                            </div>
                        </Stack>
                        <Grid container spacing={{ md: 2, lg: 4, sm:2 }}>
                            {RecentList.map((item, idx) => (
                                <Grid item xs={12} md={4} sm={6}  lg={3} key={idx} sx={{
                                    filter: isCardBlur(idx) ? "blur(11px)" : "none"
                                }}>
                                    <CardItem
                                        image={item.image}
                                        category={item.category}
                                        title={item.title}
                                        subtitle={item.subtitle}
                                        size={item.size}
                                        perMonth={item.perMonth}
                                        price={item.price}
                                        via={item.via}
                                        listedOn={item.listedOn}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
            </Box >
            <Footer />
        </>
    )
}
