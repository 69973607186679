import React, { useState, useEffect } from 'react'
import Header from '../../component/header/header'
import Footer from '../../component/footer/footer'
import SearchInputField from './edit-search-components/search-inputField'
import { useLocation } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import { postToBackend } from "../../services/backendUtility.js";
import _isEqual from 'lodash/isEqual';

export const EditSearch = () => {    
    const location = useLocation()
    const navigate = useNavigate()
    const [searchNr] = useState(location.state?.searchNr)
    const [formData, setFormData] = useState(JSON.parse(sessionStorage.getItem(process.env.REACT_APP_STORAGE_USER)).searches[searchNr]);
    const intialFormData = JSON.parse(sessionStorage.getItem(process.env.REACT_APP_STORAGE_USER)).searches[searchNr]
    const [wasChanged, setWasChanged] = useState(false)

    useEffect(() => {
        debugger
        location.state = null
        if(!searchNr){
            navigate("/profile")
        }
    }, []);

    const handleChange = (name, value) => {
        if (name === "neighborhoods"){
            value = Array.from(value).map(item => item.startsWith('- ') ? item.slice(2) : item)
        }
        debugger
        setFormData(prevFormData => ({
          ...prevFormData,
          [name]: value,
        }))
    };

    useEffect(() => {
        debugger
        setWasChanged(!_isEqual(intialFormData, formData))
    }, [formData])

    const handleSubmit = async () => {
        debugger
        const response = await postToBackend(process.env.REACT_APP_BACKEND_EDIT_SEARCH+`/${searchNr}`, 
            formData,
            {'Content-Type': 'application/json', 'Authorization': `${sessionStorage.getItem(process.env.REACT_APP_STORAGE_TOKEN)}`}
        )
        if (response.status === 200){
          let storedUser = JSON.parse(sessionStorage.getItem(process.env.REACT_APP_STORAGE_USER))
          storedUser.searches[searchNr] = formData
          sessionStorage.setItem(process.env.REACT_APP_STORAGE_USER, JSON.stringify(storedUser))
        }
        navigate("/profile")
    };
    
    return (
        <>
            <Header />
            <div>
                <SearchInputField 
                    handleSubmit={handleSubmit} 
                    handleChange={handleChange} 
                    formData={formData}
                    wasChanged={wasChanged}
                />
            </div>
            <Footer />
        </>
    )
}
