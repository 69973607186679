//import { Button, Link } from "@mui/material";
import React, {useState, useEffect, useRef, forwardRef, useImperativeHandle} from "react";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'; // Import the styles
import { FlagIcon } from 'react-flag-kit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';

export const phoneConst = 'whatsapp'

export const isValidPhone = (phone) => {
    return (phone && phone?.length >= 12 && phone?.length <= 16)
}

const CustomPhoneInput = forwardRef((props, ref) => {
    const [isPhoneFieldFocused, setiIsPhoneFieldFocused] = useState(false)
    const [phoneBorderColor, setPhoneBorderColor] = useState('white')
    const phoneField = useRef(null)

    useImperativeHandle(ref, () => ({
        focus() {
          if (phoneField.current) {
            phoneField.current.focus();
          }
        }
    }));

    const handlePhoneFieldFocus = () => {
        setiIsPhoneFieldFocused(true)
        if(props.isSignUp){
            props.handleNotificationSelectionChange(phoneConst, phoneField)
        }
    }
      
    useEffect(() => {
        if (props.contactMethod === phoneConst && props.phone && !isPhoneFieldFocused && !isValidPhone(props.phone)){
            setPhoneBorderColor('red')
        }
        else{
            setPhoneBorderColor('white')
        }
    }, [isPhoneFieldFocused, props.phone, props.contactMethod])

    return (
        <div className="form-group" style={{"margin": "0px"}}>
            <div className="form-group-col" style={{"display": "flex", "flexDirection": "row"}}>
            <div style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "width": "5%"}}>
                {props.isSignUp && 
                    <input
                        type="radio"
                        checked={props.contactMethod === phoneConst}
                        onChange={() => props.handleNotificationSelectionChange(phoneConst, phoneField)}
                    ></input>
                }
                {!props.isSignUp && <PhoneOutlinedIcon /> }
            </div>
            <div style={{"width": "100%"}}>
                <label 
                    htmlFor="phone" 
                    className="form-title"
                    name="contactMethod"
                    value="phone"   
                > 
                    {props.isSignUp ? 'via WhatsApp ' : ''} 
                    {!props.isSignUp ? 
                        (props.contactMethod === phoneConst ? 
                            (<span style={{"fontSize": "12px"}}>Mandatory </span>) 
                            : (<span style={{"fontSize": "12px"}}>Optional </span>)
                        ) : (<span></span>)
                    }
                    {props.isSignUp ?
                            (
                                (props.contactMethod === phoneConst && !isPhoneFieldFocused && props.phone && (props.phone?.length < 12)) ? 
                                    (<span style={{"fontSize": "12px"}}>Missing: {12 - props.phone?.length} digits</span>) 
                                    : props.contactMethod === phoneConst && !isPhoneFieldFocused && props.phone && !(props.phone?.length >= 12 && props.phone?.length <= 16) ? 
                                        (<span style={{"fontSize": "12px"}}>Too many digits: {props.phone?.length - 16}</span>) 
                                        : (<span></span>)
                            ):(
                                (!isPhoneFieldFocused && props.phone && (props.phone?.length < 12)) ? 
                                    (<span style={{"fontSize": "12px"}}>- missing: {12 - props.phone?.length} digits{props.contactMethod === phoneConst ? <span>.</span>:<span> or remove. </span>}</span>) 
                                    : props.contactMethod === phoneConst && !isPhoneFieldFocused && props.phone && !(props.phone?.length >= 12 && props.phone?.length <= 16) ? 
                                        (<span style={{"fontSize": "12px"}}>- too many digits: {props.phone?.length - 16}. {props.contactMethod === phoneConst ? <span>.</span>:<span> or remove. </span>}</span>) 
                                        : (<span></span>)
                            )
                    }
                </label>
                <div className="input-field-group">
                    <div className="input-phone-group" 
                        style={{
                        "border": `1px solid ${phoneBorderColor}`,
                        "opacity": props.isSignUp ? `${props.contactMethod !== phoneConst ? 0.4 : 1}` : 1
                        }}
                        fullWidth>
                        <PhoneInput
                            country="NL" // Default country
                            flagsComponent={FlagIcon} // Use CountryFlag from react-flag-kit
                            inputStyle={{
                                width: '100%',
                                padding: '10px',
                                fontSize: '16px',
                            }}
                            value={props.isSignUp ?(props.contactMethod === phoneConst ? props.phone : '') : props.phone}
                            onChange={props.handlePhoneChange}
                            onBlur={() => {setiIsPhoneFieldFocused(false)}}
                            onFocus={() => {handlePhoneFieldFocus()}}
                            ref={phoneField}
                        />
                    </div>
                    {props.isSignUp && 
                        <CheckCircleIcon className={props.contactMethod === phoneConst && props.phone && isValidPhone(props.phone) ? 'filled' : 'not-filled'} />
                    }
                    </div>
            </div>
            </div>
        </div>
    );
})

export default CustomPhoneInput;