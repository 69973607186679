import { Box, Stack, Typography } from "@mui/material";

export const AboutBanner = () => {
  return (
    <Stack
      sx={{
        backgroundImage: 'url("/img/about-banner.png")',
        padding: { xs: "130px 0 194px", md: "247px 0" },
        textAlign: "center",
        color: "white",
      }}
    >
      <Box className="container">
        <Box
          sx={{
            width: { xs: "100%", md: "70%" },
            margin: "0 auto",
            padding: { xs: "0 10px", md: "0 0" },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: "34px", md: "48px" },
              lineHeight: { xs: "46px", md: "40px" },
              marginBottom: { xs: "24px", md: "0" },
              fontWeight: { xs: 600, md: 400 },
            }}
          >
            Our team
          </Typography>
          <Typography
            variant="body1"
            sx={{ lineHeight: { xs: "26px", md: "46px" } }}
          >
           We’re always trying to improve and delve the best service to our customers.
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
};
