import { Button, Link } from "@mui/material";
import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import "./header.css"
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { IsTokenValid } from "../authentication/privateRoute";


function Header() {
    const navigate = useNavigate();
    const location = useLocation();

    debugger;
    const {loginWithRedirect, logout } = useAuth0();

    async function logoutWithRedirect() {
        debugger
        logout({
            logoutParams: {
                returnTo: window.location.origin+"/facilitate-redirect",
            }
        })
    };

  return (
    <Navbar expand="lg" className="header">
      <Container>
        <Navbar.Brand href="/" className="logoimg"><img src="/img/logo-icon.svg" />hounder</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="nav-link" >
          <Nav className="mx-auto">
            <Link href="/#homeProduct">Product</Link>
            <Link href="/#homeLearn">Learn</Link>
            <Link href="/#homePricing">Pricing</Link>
            <Link href="/contact-us">Contact</Link>
          </Nav>
          {
            !IsTokenValid() ? (
              <Nav className="d-flex align-items-center">     
                <Button onClick={() => loginWithRedirect({authorizationParams: {screen_hint: "signup"}})} className="common-btn yellow-btn mx-3">Sign Up</Button>
                <Button onClick={() => loginWithRedirect({})} className="common-btn get-btn">Log In</Button>
              </Nav>
            ) : (
              (location.pathname === "/profile" || location.pathname === "/signup") ? (
                <Nav className="d-flex align-items-center">              
                  <Button onClick={() => logoutWithRedirect()} className="common-btn yellow-btn mx-3">Logout</Button>
                </Nav>
              ) : (
                sessionStorage.getItem(process.env.REACT_APP_STORAGE_USER_AUTH0_DATA) && !sessionStorage.getItem(process.env.REACT_APP_STORAGE_USER) ? (
                  <Nav className="d-flex align-items-center">              
                    <Button onClick={() => navigate("/signup")} className="common-btn yellow-btn mx-3">Finish Profile</Button>
                  </Nav>
                ) : (
                  !sessionStorage.getItem(process.env.REACT_APP_STORAGE_USER_AUTH0_DATA) && sessionStorage.getItem(process.env.REACT_APP_STORAGE_USER) ? (
                    <Nav className="d-flex align-items-center">              
                      <Button onClick={() => navigate("/profile")} className="common-btn yellow-btn mx-3">Profile</Button>
                    </Nav>
                  ) :                 
                    <Nav className="d-flex align-items-center">              
                      <Button onClick={() => logoutWithRedirect()} className="common-btn yellow-btn mx-3">Logout</Button>
                    </Nav>
                )
              )
            )
          }
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Header;