import { useAuth0 } from "@auth0/auth0-react";
import { jwtDecode } from 'jwt-decode'; // Import jwt-decode library
import SignupComponent from '../../pages/signup/SignupComponent';
import Profile from "../../pages/profile/profile";

export function IsTokenValid() {
    debugger
    return Math.floor(Date.now() / 1000) < sessionStorage.getItem(process.env.REACT_APP_STORAGE_TOKEN_EXP) ? true : false
}

export function PrivateRoute({element}) {
    const { loginWithRedirect } = useAuth0();
    
    if (!IsTokenValid()) {
        loginWithRedirect();
        return null;
    }

    if (element.type === SignupComponent) {
        if (sessionStorage.getItem(process.env.REACT_APP_STORAGE_USER_AUTH0_DATA)) {
            return element;
        } else {
            window.location = "/profile";
            return <Profile/>
        }
    } else {
        if (!sessionStorage.getItem(process.env.REACT_APP_STORAGE_USER_AUTH0_DATA)) {
            return element;
        } else {
            window.location = "/signup";
            return <SignupComponent/>
        }
    }
};