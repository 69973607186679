import React, { useState, useEffect} from "react";
import EditProfileData from "./editProfileData"
import { Grid, Typography, Link } from "@mui/material"
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import Button from '@mui/material/Button'
import {putToBackend, deleteFromBackend} from '../../../services/backendUtility'
import { emailConst, isValidEmail } from "../../../component/user_profile/emailInput";
import { isValidPhone, phoneConst } from "../../../component/user_profile/customPhoneInput";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import _isEqual from 'lodash/isEqual';
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";


const initializeProfileData = () => {
    const tmpProfileData = {}
    const userData = JSON.parse(sessionStorage.getItem(process.env.REACT_APP_STORAGE_USER))
    if(userData){
        tmpProfileData.first_name = userData.first_name
        tmpProfileData.last_name = userData.last_name
        tmpProfileData.email = userData.email
        tmpProfileData.phone_number = userData.phone_number
        tmpProfileData.is_notified_by = userData.is_notified_by
        return tmpProfileData
    }
    return {}
}

function EditProfile() {
    debugger
    const { logout } = useAuth0();
    const navigate = useNavigate()
    const [isProfileInputFieldSet, setIsProfileInputFieldSet]=useState(false)
    const initialProfile = initializeProfileData()
    const [workingFormInfo, setProfileData] = useState(initialProfile)
    const [isProfileDataSubmittable, setIsProfileDataSubmittable] = useState(false)

    const handleChange = (name, value) => {
        debugger
        setProfileData(prevProfileData => ({
            ...prevProfileData,
            [name]: value,
        }))
    }

    useEffect(() => {
        debugger
        setIsProfileDataSubmittable(
            (
                (workingFormInfo.is_notified_by===emailConst && isValidEmail(workingFormInfo.email))
                || (workingFormInfo.is_notified_by!==emailConst && (workingFormInfo.email==="" || isValidEmail(workingFormInfo.email)))
            ) &&  (
                (workingFormInfo.is_notified_by===phoneConst && isValidPhone(workingFormInfo.phone_number))
                || (workingFormInfo.is_notified_by!==phoneConst && (!workingFormInfo.phone_number || workingFormInfo.phone_number==="" || isValidPhone(workingFormInfo.phone_number)))
            ) && (!_isEqual(initialProfile, workingFormInfo))
        )
    }, [workingFormInfo])

    
    const handleSubmit = async () => {
        debugger
        const response = await putToBackend(
            process.env.REACT_APP_BACKEND_EDIT_PROFILE,
            workingFormInfo,
            {'Content-Type': 'application/json', 'Authorization': `${sessionStorage.getItem(process.env.REACT_APP_STORAGE_TOKEN)}`}
        )
        if (response.status === 200){
            debugger
            const newUser = JSON.parse(sessionStorage.getItem(process.env.REACT_APP_STORAGE_USER))
            newUser.first_name = workingFormInfo.first_name
            newUser.last_name = workingFormInfo.last_name
            newUser.email = workingFormInfo.email
            newUser.phone_number = workingFormInfo.phone_number
            newUser.is_notified_by = workingFormInfo.is_notified_by
            sessionStorage.setItem(process.env.REACT_APP_STORAGE_USER, JSON.stringify(newUser));
            setIsProfileInputFieldSet(!isProfileInputFieldSet)
        }
    }

    const handleCloseOfForm = () => {
        setIsProfileInputFieldSet(false)
        setProfileData(initialProfile)
    }

    const deleteAccount = async () => {
        if (window.confirm("Are you sure you want to delete your account?")) {
            try {
                //TODO: delete user from auth0 here as well.
                
                // if (auth0Response.status === 200) {
                await deleteFromBackend(process.env.REACT_APP_BACKEND_DELETE_USER_ACCOUNT, 
                    {'Content-Type': 'application/json', 'Authorization': `${sessionStorage.getItem(process.env.REACT_APP_STORAGE_TOKEN)}`}
                )
                sessionStorage.removeItem(process.env.REACT_APP_STORAGE_USER);
                sessionStorage.removeItem(process.env.REACT_APP_STORAGE_TOKEN_EXP);
                sessionStorage.removeItem(process.env.REACT_APP_STORAGE_TOKEN);
                logout({
                    logoutParams: {
                        returnTo: window.location.origin+"/facilitate-redirect",
                    }
                })
                navigate("/");
                    //TODO: call auth0 to delete account there as well.
                // }
            } catch (error) {
                alert('An error occurred. Please check your internet connection and try again.');
            }
        }
    }

    return (
        <Grid variant="div" className="mb-5">
            <Grid variant="div" className="profile-section">
                <Typography style={{paddingBottom: "10px"}}>
                    Profile
                </Typography>
                {isProfileInputFieldSet && 
                    <div>
                        <IconButton aria-label="close" style={{backgroundColor: "red", marginBottom:"10px"}} >
                            <CloseIcon
                                onClick={() => handleCloseOfForm()}
                                style={{color: "white"}}
                            />
                        </IconButton>
                        <EditProfileData
                            formData = {workingFormInfo}
                            handleChange={handleChange}
                            deleteAccount={deleteAccount}
                        />
                    </div>
                }
                {!isProfileInputFieldSet && 
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={5}>
                            <MailOutlineIcon />  {workingFormInfo.email ? workingFormInfo.email : "None"}
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <PhoneOutlinedIcon /> {workingFormInfo.phone_number ? workingFormInfo.phone_number : "None"}
                        </Grid>
                        <Grid item xs={12} md={5}>
                            Is notified by: {workingFormInfo.is_notified_by}
                        </Grid>
                        <Grid item xs={12} md={5}>
                            Subscription type: FREE
                        </Grid>
                        <Grid item xs={12} md={5}>
                            First Name: {workingFormInfo.first_name}
                        </Grid>
                        <Grid item xs={12} md={5}>
                            Last Name: {workingFormInfo.last_name}
                        </Grid>
                    </Grid>
                }
                <div className="form-group" style={{display: "flex", width: "100%"}}>
                    <div style={{display: "flex", width: "100%"}}>
                        {isProfileInputFieldSet ?
                            <Button
                                variant="contained"
                                style={{alignItems: "center", justifyContent: "center"}}
                                className={
                                (isProfileDataSubmittable) ? 
                                    "submit-button submitted"
                                    : "submit-button disabled"}
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>

                            : <Link onClick={() => setIsProfileInputFieldSet(!isProfileInputFieldSet)}>Edit Information</Link>
                        }
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}
export default EditProfile;