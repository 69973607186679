import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { jwtDecode } from 'jwt-decode';
import { postToBackend } from '../../services/backendUtility'


function retrieveUserInfo(user){
    return {
        "email": user.email,
        "first_name": user.given_name,
        "last_name": user.family_name
    }
};

function getTokenExpiration(token){
    const decodedToken = jwtDecode(token);
    return decodedToken.exp
}

export function Auth0RedirectURI() {
    const navigate = useNavigate();
    
    const {
        user,
        isAuthenticated,
        getAccessTokenSilently,
        isLoading,
        logout
      } = useAuth0();
    

    useEffect(() => {
        const checkAuthentication = async () => {
            if (isLoading) return; // Wait until Auth0 finishes loading
      
            try {
                debugger
                if(isAuthenticated){
                    const accessToken = await getAccessTokenSilently();
                    const timestampUntilExpiration = getTokenExpiration(accessToken)

                    sessionStorage.setItem(process.env.REACT_APP_STORAGE_TOKEN_EXP, timestampUntilExpiration);
                    // Alternatively, you could use Auth0 Actions
                    debugger
                    const userSchema = retrieveUserInfo(user)
                    var response = await postToBackend(process.env.REACT_APP_BACKEND_LOGIN, 
                        userSchema, 
                        {'Content-Type': 'application/json', 'Authorization': `${accessToken}`}
                    )
                    sessionStorage.setItem(process.env.REACT_APP_STORAGE_TOKEN, accessToken)

                    // HTTP 200 means user was OK. I.e. user already exists.
                    if (response.status === 200){
                        sessionStorage.setItem(process.env.REACT_APP_STORAGE_USER, JSON.stringify(response.body));
                        navigate("/profile");
                    } // HTTP 202 means accepted (I.e. user existed but without any filters set). and 201 means user was created (i.e. signup)
                    else if(response.status === 202 || response.status === 201){
                        sessionStorage.setItem(process.env.REACT_APP_STORAGE_USER_AUTH0_DATA, JSON.stringify(response.body));
                        navigate("/signup");
                    }
                    else{
                        sessionStorage.removeItem(process.env.REACT_APP_STORAGE_TOKEN_EXP);
                        sessionStorage.removeItem(process.env.REACT_APP_STORAGE_TOKEN);
                        sessionStorage.removeItem(process.env.REACT_APP_STORAGE_USER);
                        sessionStorage.removeItem(process.env.REACT_APP_STORAGE_USER_AUTH0_DATA);
                        logout({
                            logoutParams: {
                                returnTo: window.location.origin+"/facilitate-redirect",
                            }
                        })
                        navigate("/"); 
                    }
                }
                else{
                    debugger
                    sessionStorage.removeItem(process.env.REACT_APP_STORAGE_TOKEN_EXP);
                    sessionStorage.removeItem(process.env.REACT_APP_STORAGE_TOKEN);
                    sessionStorage.removeItem(process.env.REACT_APP_STORAGE_USER);
                    sessionStorage.removeItem(process.env.REACT_APP_STORAGE_USER_AUTH0_DATA);
                    navigate("/"); 
                }
            } catch (error) {
                console.error('Error retrieving access token:', error);
            }
        };
        checkAuthentication();
    }, [isLoading, getAccessTokenSilently, isAuthenticated, navigate, user, logout]);
    
    return (
        <div>Processing...</div>
    )
};
