//import { Button, Link } from "@mui/material";
import React, {useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import TextField from '@mui/material/TextField';
import 'react-phone-number-input/style.css'; // Import the styles
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

export const emailConst = 'email'

export const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

const EmailInput = forwardRef((props, ref) => {
    debugger
    const [isEmailFieldFocused, setiIsEmailFieldFocused] = useState(false)
    const [emailBorderColor, setEmailBorderColor] = useState('white')
    const emailField = useRef(null);

    useImperativeHandle(ref, () => ({
        focus() {
          if (emailField.current) {
            emailField.current.focus();
          }
        }
    }));

    const handleEmailFieldFocus = () =>{
        setiIsEmailFieldFocused(true)
        if(props.isSignUp){
            props.handleNotificationSelectionChange(emailConst, emailField)
        }
     }

     useEffect(()=>{
        debugger
        if (props.contactMethod === emailConst && props.email && !isEmailFieldFocused && !isValidEmail(props.email)){
            setEmailBorderColor('red')
        }
        else{
            setEmailBorderColor('white')
        }
     }, [isEmailFieldFocused, props.email, props.contactMethod])
   

    return (
        <div className="form-group" style={{"margin": "0px"}}>
            <div className="form-group-col" style={{"display": "flex", "flexDirection": "row"}}>
                <div style={{"display": "flex", "alignItems": "center", "justifyContent": "center", "width": "5%"}}>
                    {props.isSignUp && 
                        <input
                            type="radio"
                            checked={props.contactMethod === emailConst}
                            onChange={() => props.handleNotificationSelectionChange(emailConst, emailField)}
                        ></input>
                    }
                    {!props.isSignUp && <MailOutlineIcon />}
                </div>
                <div style={{"width": "100%", fontSize: "1px"}}>
                    <label
                        htmlFor="email" 
                        className="form-title"
                        name="contactMethod"
                        value="email"
                    > 
                        {props.isSignUp ? 'via Email' : ''}
                        {!props.isSignUp ? 
                            (props.contactMethod === emailConst ? 
                                <span style={{"fontSize": "12px"}}> Mandatory </span> 
                                : <span style={{"fontSize": "12px"}}> Optional </span>
                            )
                            : (<span></span>)
                        }
                        {props.isSignUp ? 
                            (props.contactMethod === emailConst && !isEmailFieldFocused && props.email && !isValidEmail(props.email)) ? 
                                (<span style={{"fontSize": "12px"}}> Not valid email format </span>) 
                                : (<span></span>)
                            : (!isEmailFieldFocused && props.email && !isValidEmail(props.email)) ? 
                                (<span style={{"fontSize": "12px"}}> - not valid email format{props.contactMethod === emailConst ? <span>.</span>:<span>, correct or remove.</span>}</span>)
                                : (<span></span>)
                        }
                    </label>
                        <div className="input-field-group">
                            <TextField
                                name="signin_email"
                                placeholder="Type your email..."
                                variant="outlined"
                                fullWidth
                                value={props.isSignUp ? (props.contactMethod === emailConst ? props.email : '') : props.email}
                                style={{
                                    "border": `1px solid ${emailBorderColor}`,
                                    "opacity": props.isSignUp ? `${props.contactMethod !== emailConst ? 0.4 : 1}` : 1
                                }}
                                onChange={props.handleEmailChange}
                                onBlur={() => {setiIsEmailFieldFocused(false)}}
                                onFocus={() => {handleEmailFieldFocus()}}
                                inputRef={emailField}
                            />
                            {props.isSignUp &&
                                <div>
                                    {props.contactMethod === emailConst && props.email !== "" && isValidEmail(props.email) ? (
                                            <CheckCircleIcon className="filled" />
                                        ) : (
                                            <CheckCircleIcon className="not-filled" />
                                        )
                                    }   
                                </div>
                            }
                    </div>    
                </div>
            </div>
        </div>
    )
})

export default EmailInput;